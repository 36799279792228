/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "hooks/useForm";
import ButtonView from "../CorrectionForm/Logs/BtnLog";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { SubTitleHeader } from "components/HeaderForm/Style";
import { FmmOHeader } from "./Formulario de cabecera/FmmOHeader";
import { FmmOheadercorrected } from "./Formulario de cabecera/FmmOheadercorrected";
import FormCrudCRUDContext from "context/FormCrudContex";
// import { AuthContext } from "context/AuthContext";
import { TitleOperationOutput } from "../TitleOperation/TitleOperationOutput";
import { __GetDataId } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { useSelector, useDispatch } from "react-redux";
import { NationalTerritoryCorrect } from "./container/NationalTerritoryCorrect";
import { RestOfTheWorldCorrect } from "./container/RestOfTheWorldCorrect";
import { UserToUserCorrect } from "./container/UserToUserCorrect";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import { BtnWithToltip } from "components/BtnWithToltip/BtnWithToltip";
import { CgPlayListAdd } from "react-icons/cg";
import { ButtonStyled } from "styled/Buttons.styled";
import { FmmItemOriginal } from "./item/FmmItemOriginal";
import { FmmOheadercorrectedShow } from "./Formulario de cabecera/FmmOheadercorrectedShow";
import Pagination from "components/Pagination/Pagination";
import { TitleItemFmm, TitleItemFmmShow } from "../arrayHeadItems";
import TBodyItemCorrect from "../Table/TBodyItemCorrect";
import TBodyItemCorrectShow from "../Table/TBodyItemCorrectShow";
import { FmmItemCorregido } from "./item/FmmItemCorregido";
import { __GetItems, __ShowItems } from "request/Forms/_CrudForm";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Trm } from "request/configuration/__Trm";
import ModalDelete from "components/modal/ModalDelete";
import DocumentUploader from "../FormEntry/DocumentUploader/DocumentUploader";
import Vapor from "laravel-vapor";
import { Commentary } from "components/Commentary/Commentary";
import { __CommentaryForm } from "request/Forms/__ShowForm";
import { ExportPdfOutputCorrect } from "../Export/pdfCorrect/ExportPdfOutputCorrect";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { SubTitle } from "styled/Title";

import { useFormatQuantity } from "hooks/useFormatQuantity";
import { CapsulaContainerOutput } from "./CapsulaContainerOutput";
import TablePass from "components/Tables/TablesPass";
import { __OperationListCorrected } from "request/configuration/__Operations";
import { AuthContext } from "context/AuthContext";
import { __Post } from "request/Petitions/__Post";
import { parseFloat } from "utils/parseFloat";
import {
  setFormData,
  clearFormData,
  getFormData,
  updateFormData,
} from "redux/actions/dataFormActions";
import { useFormBlocker } from "hooks/useFormBlocker";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import useStatusListener from "hooks/useStatusListener";
import {
  isNonEmptyString,
  isNumber,
  isNumberFormatable,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
} from "utils/type-check-utils";
import { createKey, createObjectFromItem } from "../FormExit/BodyFormExit";
import { OtherAttchment } from "components/attchmentTypiFied/OtherAttchment";
import { Storage } from "hooks/useStorage";
import { GroupInputUpload } from "../GroupInputUpload";
import { useConfirmationModal } from "hooks/useConfirmationModal";
import InputSelect from "components/InputSelect/InputSelect";
import EnhancedFileUploadManager from "pages/CustomsClearance/MultipleFileUploader";
import ExistingFiles from "pages/CustomsClearance/Adjunto/ExistingFiles";
import { convertArrayToGroupedObject } from "../FormEntry/BodyForm";
import FileUploader from "../FormEntry/DocumentUploader/FileUploader";
import CreateAt from "../View/CreateAt";
import Swal from "sweetalert2";

export const CorrectionFmmO = () => {
  const { token, decimal_values_precision, is_commerce } = useSelector(
    (state) => state.auth.dataUser
  );
  const dataForm = useSelector(getFormData);
  const [fomateo] = useFormatQuantity();
  const [quantityError, setquantityError] = useState([]);

  const [isLoadingShow, setIsLoadingShow] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [openAlert] = AlertErrorAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // navigate
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [suma, setSuma] = useState();
  const [multiplicacion, setMultiplicacion] = useState();
  const [sumaCorrect, setSumaCorrect] = useState();
  const [multiplicacionCorrect, setMultiplicacionCorrect] = useState();
  const [attachment, setAttachment] = useState([]);
  const [attachmentDescription, setAttachmentDescription] = useState([]);
  const [attachmentType, setAttachmentType] = useState([]);
  const [nameFiles, setNameFiles] = useState([]);
  const [commentarios, setComentarios] = useState([]);
  const [idQualified, setIdQualified] = useState();
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" });
  const [listFiles, setListFiles] = useState([]);

  const [LoadingCocument, setLoadingCocument] = useState({
    label: false,
    name: "",
  });

  const { FormOutput = null, ShowOutput = null } = queryString.parse(
    location.search
  );

  const dispatchRedux = useDispatch();

  const [valuesHeader, handleInputChangeHeader, resetHeader, setValuesHeader] =
    useForm({});

  const {
    status_outputFormCorrected,
    code_outputFormCorrected,
    name_qualified,
    business_name,
    // code_operation,
    operationCorrected_id,
    thirdPartieCorrected_id,
    thirdPartie_id,
  } = valuesHeader;

  const onFieldChange = (name, newValue, target) => {
    const parsedValue = parseFloat(newValue);

    if (ShowOutput) return;
    if (!valuesItem?.factor_conv_corrected) return;
    if (name === "product_quantity_corrected") {
      return setValuesItem((prev) => ({
        ...prev,
        product_quantity_corrected: parsedValue,
        quantity_corrected:
          parsedValue * parseFloat(valuesItem?.factor_conv_corrected),
        ...(valuesItem?.code_unit_measurement_corrected &&
          valuesItem?.code_unit_measurement_corrected?.toUpperCase() ===
            "KG" && {
            gross_weight_corrected:
              parsedValue * parseFloat(valuesItem?.factor_conv_corrected),
            net_weight_corrected:
              parsedValue * parseFloat(valuesItem?.factor_conv_corrected),
          }),
      }));
    }
    if (name === "factor_conv_corrected") {
      return setValuesItem((prev) => ({
        ...prev,
        quantity_corrected:
          parseFloat(newValue) *
          parseFloat(valuesItem?.product_quantity_corrected),
        gross_weight_corrected:
          parseFloat(newValue) *
          parseFloat(valuesItem?.product_quantity_corrected),
        net_weight_corrected:
          parseFloat(newValue) *
          parseFloat(valuesItem?.product_quantity_corrected),
      }));
    }
  };

  const [valuesItem, handleInputChangeItem, resetItem, setValuesItem] = useForm(
    {},
    null,
    onFieldChange
  );

  const {
    us_freight,
    us_insurance,
    other_us_expenses,
    us_fob_value,
    exchange_rate,
    exchange_rate_corrected,
    us_freight_corrected,
    us_insurance_corrected,
    other_us_expenses_corrected,
    us_fob_value_corrected,
    product_quantity_corrected,
    factor_conv_corrected,
    code_businessUnit_correct,
    original_fob_value_us_corrected,
    original_fob_value_us,
  } = valuesItem;

  // const { authUser } = useContext(AuthContext);

  const {
    info,
    packagings,
    valueFlag,
    valueMethod,
    dataCountryBuy,
    dataCountryOrigin,
    setValueSubheading,
    getThirdParties,
    update,
    dataThirdDefault,
    setStateCurrentExport,
    getOperationOutput,
    dataOperation,
    setDataSelectOperation,
    setOpcional,
    opcional,
    dataDUTA,
    opcionalCurrent,
    setOpcionalCurrent,
    setselectOperation,
    setselectExport,
    setselectEmbarcacion,
    setRefunds,
    updateCorrectDocument,
    refunds,
    selectEmbarcacion,
    selectOperation,
    selectExport,
    setRequestDelete,
    dataToEditItems,
    dataToCopyItems,
    getCountry,
    getPackaging,
    getMethodTransport,
    getCodeProduct,
    dataAgreements,
    setDataAgreementsNew,
    dataPackagings,
    setDataPackagingsNew,
    dataTransportMethod,
    setDataTransportMethodNew,
    setValueProducts,
    setInfo,
    setDataCountryOrigin,
    setValueFlag,
    setPackagings,
    setValueMethod,
    setDataCountryBuy,
    setDataToEditItems,
    setDataToCopyItems,
    valueProducts,
    allProducts,
    valueSubheading,
    CorrectItem,
    createCommentary,
    isOpenDelete,
    onCloseModal,
    dataToDelete,
    deleteItemsCorrect,
    deleteAttachment,
    newCommentary,
    setStateNit,
    StateNit,
    stateCollapse,
    setstateCollapse,
    setshowItemsView,
    setRequestChangeStatusForm,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    changeStatusCorregido,
    isOpenRecuperar,
    onOpenRecuperar,
    onCloseRecuperar,
    dataRecuperar,
    setbalanceCorrecedState,
    setUpdate,
    setCustomsadministration,
    customsadministration,
  } = useContext(FormCrudCRUDContext);
  const [DocumentOperation, setDocumentOperation] = useState([]);
  const [code, setCode] = useState(null);
  const [files, setFiles] = useState({});
  const [otherFiles, setOtherFiles] = useState([]);

  const {
    closeModal: onCloseUpload,
    openModal: onOpenUpload,
    isOpen: isOpenUpload,
  } = useConfirmationModal();
  const { setDataCorrected } = useGlobalContext();

  const workDirty = (data, isUpdateFile) => {
    if (isUpdateFile) {
      const outputObject = convertArrayToGroupedObject(data?.files || []);

      setListFiles(outputObject);

      const fromServer = data.files?.reduce((accumulator, item, index) => {
        const key = createKey(item.documentReq_id, item?.doc_corrected_req);
        accumulator[key] = createObjectFromItem(item, index);
        return accumulator;
      }, {});

      setFiles(Object.assign({}, files, fromServer));
      setDataCorrected((prev) => ({
        ...prev,
        files: Object.assign({}, files, fromServer),
      }));
      setDeleteFile(false);
      return;
    }

    handleCancelCabecera();
    setValuesHeader(data);
    setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMO_CORRECT);

    const {
      qualifiedUser_id,
      code_operation,
      check_duta,
      check_duta_corrected,
      shipment_corrected,
      refund_corrected,
      documents,
      qualifiedUser4_id,
      nit_qualified4,
      name_qualified4,
      balance_correction,
      customAdministrationCorrected_id,
      custom_code_corrected,
      custom_name_corrected,
    } = data;

    if (balance_correction === 1) {
      setbalanceCorrecedState(balance_correction);
    } else {
      setbalanceCorrecedState(null);
    }

    setDocumentOperation(documents);
    const outputObject = convertArrayToGroupedObject(data?.files || []);
    setListFiles(outputObject);
    const fromServer = data.files?.reduce((accumulator, item, index) => {
      const key = createKey(item.documentReq_id, item?.doc_corrected_req);
      accumulator[key] = createObjectFromItem(item, index);
      return accumulator;
    }, {});

    setFiles(Object.assign({}, files, fromServer));
    setDataCorrected((prev) => ({
      ...prev,
      files: Object.assign({}, files, fromServer),
    }));

    const otherFiles = convertArrayToGroupedObject(
      data?.other_files || [],
      "other_files"
    );

    setOtherFiles(otherFiles);

    // getCodeProduct(qualifiedUser_id);
    if (customAdministrationCorrected_id) {
      setCustomsadministration({
        label: custom_code_corrected + " " + custom_name_corrected,
        value: customAdministrationCorrected_id,
      });
    }

    if (check_duta) {
      setOpcionalCurrent(check_duta);
    }

    if (check_duta_corrected) {
      setOpcional(check_duta_corrected);
    }

    if (refund_corrected) {
      setRefunds({ label: refund_corrected, value: refund_corrected });
    }

    if (shipment_corrected) {
      setselectEmbarcacion({
        label: shipment_corrected,
        value: shipment_corrected,
      });
    }
    if (qualifiedUser4_id) {
      setStateNit({
        value: qualifiedUser4_id,
        label: nit_qualified4 + " " + name_qualified4,
      });
    }

    if (qualifiedUser_id) {
      setIdQualified(qualifiedUser_id);
      getThirdParties(qualifiedUser_id);
    }

    getOperationOutput(qualifiedUser_id);
    setCode(code_operation);
  };
  const { requestData } = useGlobalContext();
  const [deleteFile, setDeleteFile] = useState(false);
  const queryClient = useQueryClient();

  const firstRender = useRef(true);
  const {
    data: dataViewFormCorrect,
    isFetching: isFetchingViewFormCorrect,
    isSuccess: isSuccessViewFormCorrect,
    isError: isErrorViewFormCorrect,
    status: statusViewFormCorrect,
  } = useQuery({
    queryKey: ["viewFormCorrect", FormOutput, ShowOutput, update],
    queryFn: () =>
      requestData({
        Endpoint: environment.CORRECT_FMMO_SHOW,
        resourceIdentifier: FormOutput || ShowOutput,
      }),
    refetchOnWindowFocus: false,
  });

  const {
    data: dataViewFiles,
    isFetching: isFetchingViewFiles,
    isSuccess: isSuccessViewFiles,
    isError: isErrorViewFiles,
    status: statusViewFiles,
  } = useQuery({
    queryKey: ["ViewFiles", FormOutput, ShowOutput, update],
    queryFn: () =>
      requestData({
        Endpoint: environment.CORRECT_FMMO_SHOW,
        resourceIdentifier: FormOutput || ShowOutput,
      }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const dataFormCurrent = dataViewFiles?.data?.status;

    if (isSuccessViewFiles && !isFetchingViewFiles && deleteFile) {
      filesRead(dataFormCurrent?.data, true);
      return;
    }

    if (isErrorViewFiles) {
      openAlert(dataFormCurrent?.data?.status.message, "error");
      navigate(-1);
      return;
    }

    if (isSuccessViewFiles && !isFetchingViewFiles) {
      const { data } = dataFormCurrent;
      filesRead(data);
    }
  }, [isFetchingViewFiles, statusViewFiles, dataViewFiles]);

  const filesRead = (data, isUpdateFile) => {
    if (isUpdateFile) {
      const outputObject = convertArrayToGroupedObject(data?.files || []);
      setListFiles(outputObject);

      const fromServer = data.files?.reduce((accumulator, item, index) => {
        const key = createKey(item.documentReq_id, item?.doc_corrected_req);
        accumulator[key] = createObjectFromItem(item, index);
        return accumulator;
      }, {});

      setFiles(Object.assign({}, files, fromServer));
      setDataCorrected((prev) => ({
        ...prev,
        files: Object.assign({}, files, fromServer),
      }));
      setDeleteFile(false);
      return;
    }

    const { documents } = data || {};

    setDocumentOperation(documents);
    const outputObject = convertArrayToGroupedObject(data?.files || []);
    setListFiles(outputObject);

    const fromServer = data.files?.reduce((accumulator, item, index) => {
      const key = createKey(item.documentReq_id, item?.doc_corrected_req);
      accumulator[key] = createObjectFromItem(item, index);
      return accumulator;
    }, {});

    setFiles(Object.assign({}, files, fromServer));
    const otherFiles = convertArrayToGroupedObject(
      data?.other_files || [],
      "other_files"
    );

    setOtherFiles(otherFiles);
  };

  useEffect(() => {
    const dataFormCurrent = dataViewFormCorrect?.data?.status;

    if (isSuccessViewFormCorrect && !isFetchingViewFormCorrect && deleteFile) {
      workDirty(dataFormCurrent?.data, true);
      return;
    }

    if (isErrorViewFormCorrect) {
      openAlert(dataFormCurrent?.data?.status.message, "error");
      navigate(-1);
      return;
    }

    if (isSuccessViewFormCorrect && !isFetchingViewFormCorrect) {
      const { data } = dataFormCurrent;

      dispatchRedux(
        setFormData({
          ...data,
          precision: Number(data?.precision),
        })
      );
      if (
        FormOutput &&
        (data.status_outputFormCorrected === "BORRADOR" ||
          data.status_outputFormCorrected === "DEVUELTO")
      ) {
        workDirty(data);
      } else {
        if (FormOutput) {
          openAlert(
            `El formulario ${data?.code_entryFormCorrected} esta en estado ${data?.status_entryFormCorrected} y no puede ser editado`,
            "error"
          );
        }
        if (data?.id) navigate(`/FormCorrectionOutput?ShowOutput=${data.id}`);

        workDirty(data);
      }
    }
  }, [isFetchingViewFormCorrect, statusViewFormCorrect, dataViewFormCorrect]);

  const [otherDocuments, setOtherDocuments] = useState([]);

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ["ViewFiles"] });
      return;
    }
  };

  const getTrm = async () => {
    try {
      const res = await __Trm(token);
      setValuesItem({
        ...valuesItem,
        exchange_rate_corrected: parseFloat(res?.data?.status?.data.value_trm),
      });
      // setValorDolarActual(res?.data?.status?.data.value_trm);
    } catch (error) {}
  };

  const handleSubmit = (updateBlur) => {
    delete valuesHeader.operationCorrected_id;
    delete valuesHeader.thirdPartieCorrected_id;
    delete valuesHeader.modality_corrected;
    delete valuesHeader.shipment_corrected;
    delete valuesHeader.check_duta_corrected;
    delete valuesHeader.customAdministrationCorrected_id;
    delete valuesHeader.documents;
    delete valuesHeader.users;
    if (selectOperation === null) {
      return openAlert("Seleccione una operación", "error");
    }

    const formData = new FormData();
    Object.entries(valuesHeader).map(([key, valor]) => {
      if (valor) {
        formData.append(`${key}`, valor);
      }
    });

    formData.append("operationCorrected_id", selectOperation.id);
    formData.append(
      "thirdPartieCorrected_id",
      selectExport ? selectExport?.value : ""
    );
    formData.append("refund_corrected", refunds ? refunds?.value : "");
    formData.append("shipment_corrected", selectEmbarcacion?.value);
    formData.append("check_duta_corrected", opcional || "");
    formData.append(
      "customAdministrationCorrected_id",
      customsadministration ? customsadministration?.value : ""
    );

    if (StateNit) {
      formData.append("qualifiedUser4_id", StateNit ? StateNit?.value : "");
    }

    updateCorrectDocument(
      environment.CORRECT_FMMO_UPDATE,
      formData,
      handleCancelCabecera,
      updateBlur && updateBlur?.includes("/")
        ? updateBlur
        : "/FormCorrectionOutput?ShowOutput"
    );
  };

  const editUploadFiles = (data) => {
    const formData = new FormData();
    formData.append("id", FormOutput || ShowOutput);
    if (data?.id && !data?.doc_corrected_req)
      formData.append("attachment_id[]", data.id);

    data?.doc_corrected_req &&
      data?.id &&
      formData.append("document_corrected_id[]", data.id);

    if (isNonEmptyString(data?.attachment_comment)) {
      formData.append("attachment_comment[]", data.attachment_comment);
    }

    if (isNonEmptyString(data?.attachment_number)) {
      formData.append("attachment_number[]", data.attachment_number);
    }

    const date = data?.other_attachment_date || data?.attachment_date;

    data?.attachment_date && formData.append("attachment_date[]", date);

    updateAndCreate(formData, true);
  };

  const updateAndCreate = async (
    data = undefined,
    payloadManual = false,
    isCorrected = null
  ) => {
    const formData = new FormData();
    formData.append("id", FormOutput || ShowOutput);
    const dataAttachments = attachment[0];
    const vaporRes = (await data) || null;

    dataAttachments?.id &&
      formData.append("other_attachment_id[]", dataAttachments?.id);
    const file = {
      file: vaporRes,
    };
    (dataAttachments?.id || dataAttachments?.other_attachment_description) &&
      formData.append(
        "other_attachment[]",
        JSON.stringify({ ...file, name: dataAttachments?.file?.name })
      );
    dataAttachments?.other_attachment_description &&
      formData.append(
        "other_attachment_description[]",
        dataAttachments?.other_attachment_description
      );
    dataAttachments?.other_attachment_comment &&
      formData.append(
        "other_attachment_comment[]",
        dataAttachments?.other_attachment_comment
      );

    dataAttachments?.other_attachment_number &&
      formData.append(
        "other_attachment_number[]",
        dataAttachments?.other_attachment_number
      );

    dataAttachments?.extension &&
      formData.append("other_attachment_type[]", dataAttachments?.extension);

    const date =
      dataAttachments?.other_attachment_date ||
      dataAttachments?.attachment_date;
    date && formData.append("other_attachment_date[]", date);

    if (isObject(data) && isObjectNotEmpty(data) && !payloadManual) {
      Object.keys(data).forEach((documentId) => {
        if (!data[documentId].from_server) {
          formData.append(
            isNumberFormatable(isCorrected) && Number(isCorrected) === 1
              ? "document_corrected[" + adjustDocumentName(documentId) + "]"
              : "attachment[" + documentId + "]",
            JSON.stringify(data[documentId])
          );
        }
      });
    }

    const requestData = {
      data: payloadManual ? data : formData,
      Endpoint: environment.CORRECT_FMMO_UPDATE_DOCUMENT,
      PropertyBody: "sendFormDataContentPOST",
    };

    // setOtherDocuments([]);
    setAttachment([]);
    setFiles({});

    mutate(requestData, {
      onSuccess: handleResponseUpdateForm,
    });
  };

  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  const onUploadFile = ({ files, setFiles, id, doc_corrected_req = 0 }) => {
    const docType = parseFloat(doc_corrected_req);

    const formData = new FormData();
    formData.append("id", FormOutput ?? ShowOutput);

    if (isValid(id)) {
      formData.append(
        docType === 1 ? "document_corrected" : "attachment",
        JSON.stringify(files[id])
      );
    } else {
      const result = Object.values(files).flatMap((documents) => documents);
      formData.append("attachment", JSON.stringify(result));
    }

    const reset = () => {
      if (isValid(id)) {
        const { [id]: _, ...rest } = files || {};
        setFiles(rest);
      } else {
        setFiles({});
      }
    };

    const requestData = {
      data: formData,
      Endpoint: environment.CORRECT_FMMO_UPDATE_DOCUMENT,
      PropertyBody: "SENDJSONCONTENTPOST",
    };

    mutate(requestData, {
      onSuccess: (res) => handleResponseUpdateForm(res, reset),
    });
  };

  const responseDeleteFile = (res) => {
    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      setOtherFiles([]);
      //setOtherDocuments([]);
      queryClient.invalidateQueries({ queryKey: ["ViewFiles"] });

      return openAlert("El documento ha sido eliminado ", "error");
    }
  };

  const removeFileByIdentifier = (
    id,
    fileNameOrID,
    dataDocument = {},
    typeComponent
  ) => {
    console.log("dataDocument", dataDocument);
    const IsTypeNewFile =
      dataDocument?.doc_corrected_req === 1 ? "/doc_corrected_req" : "";
    const Endpoint = environment.DELETE_ATTACHMENT_OUTPUT_CORRECT;
    const requestData = {
      resourceIdentifier: `${dataDocument?.id}${IsTypeNewFile}`,
      Endpoint,
    };

    mutate(requestData, {
      onSuccess: (res) => responseDeleteFile(res, id),
    });

    //DELETE_OTHER_ATTACHMENT_ENTRY_CORRECT
    /*   Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${files?.[archivo]?.name}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (files[archivo] !== undefined && files[archivo] !== null) {
          const obj = Object.assign({}, { ...files });
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            delete obj[archivo];
            if (files[archivo].from_server) {
              deleteAttachment(
                environment.DELETE_ATTACHMENT_ENTRY_CORRECT,
                files[archivo].id,
                "",
                setFiles,
                obj,
                () => {
                  setDeleteFile(true);
                  refetchViewFormCorrect();
                }
              );
            } else {
              setFiles(obj);
            }
          }
        }
      }
    }); */
  };

  const getItems = async (idEntry) => {
    const formData = new FormData();
    formData.append("outputFormCorrected_id", idEntry);
    formData.append("length", 10);
    orderBy?.value && formData.append("orderBy", orderBy?.value);

    setLoadingItems(true);
    setRequestDelete(environment.DELECT_CORRECT_ITEM_OUTPUT);
    try {
      const res = await __GetItems(
        token,
        formData,
        page,
        environment.GET_CORRECT_ITEM_OUTPUT
      );
      const respuesta = res?.data?.status;
      if (respuesta.code === 200) {
        setCurrentPage(respuesta?.data?.data);
        setMaxPage(respuesta?.data?.last_page);
      } else {
        openAlert(respuesta.message, "error");
      }
    } catch (error) {
    } finally {
      setLoadingItems(false);
    }
  };

  const showItems = async (id) => {
    setIsLoadingShow(true);
    try {
      const res = await __ShowItems(
        token,
        id,
        environment.SHOW_CORRECT_ITEM_OUTPUT
      );
      const response = res?.data?.status;
      const { code, data, message } = response;
      if (code === 200) {
        const {
          reference_description_corrected,
          productCorrected_id,
          code_country_ori_corrected,
          name_country_ori_corrected,
          countryOriginCorrected_id,
          countryProcedencyCorrected_id,
          code_country_purs_corrected,
          name_country_purs_corrected,
          countryPurchaseCorrected_id,
          code_contry_flag_corrected,
          name_country_flag_corrected,
          flagCorrected_id,
          code_packaging_corrected,
          name_packaging_corrected,
          packagingCorrected_id,
          code_transportMethod_corrected,
          name_transportMethod_corrected,
          transportMethodCorrected_id,
          code_country_des,
          subheadingCorrected_id,
          code_country_des_corrected,
          countryDestinyCorrected_id,
          name_country_des_corrected,
          customs_clearance_corrected,
        } = data || {};

        dispatchRedux(updateFormData({ items: data }));
        setstateCollapse(false);
        setValuesItem({
          ...data,
          product_quantity_corrected: parseFloat(
            data?.product_quantity_corrected
          ),
          quantity_corrected: parseFloat(data?.quantity_corrected),
          gross_weight_corrected: parseFloat(data?.gross_weight_corrected),
          net_weight_corrected: parseFloat(data?.net_weight_corrected),
          number_packages_corrected: parseFloat(
            data?.number_packages_corrected
          ),
          us_freight_corrected: parseFloat(data?.us_freight_corrected),
          us_insurance_corrected: parseFloat(data?.us_insurance_corrected),
          other_us_expenses_corrected: parseFloat(
            data?.other_us_expenses_corrected
          ),
          original_fob_value_us_corrected: parseFloat(
            data?.original_fob_value_us_corrected
          ),
          us_fob_value_corrected: parseFloat(data?.us_fob_value_corrected),
          adjustment_justification_fob_corrected:
            data?.adjustment_justification_fob_corrected,
          cif_us_value: parseFloat(data?.cif_us_value),
          exchange_rate_corrected: parseFloat(data?.exchange_rate_corrected),
          cif_cop_value: parseFloat(data?.cif_cop_value),
          added_value_us_corrected: parseFloat(data?.added_value_us_corrected),
          refund_value_us_corrected: parseFloat(
            data?.refund_value_us_corrected
          ),
          cif_value_sdo_corrected: parseFloat(data?.cif_value_sdo_corrected),
        });

        setValueProducts({
          label: `${
            reference_description_corrected +
            (customs_clearance_corrected
              ? " Dcl-" + customs_clearance_corrected
              : "")
          }`,
          value: productCorrected_id !== null ? productCorrected_id : "",
          initial_operation: data?.initial_operation,
        });

        countryOriginCorrected_id &&
          setInfo({
            label:
              code_country_ori_corrected + " " + name_country_ori_corrected,
            value: countryOriginCorrected_id,
          });

        /* value?.origin_id ? setDataCountryOrigin({
          label: `${value?.origin_name ? (value?.origin_code ? value?.origin_code + " " : "") + value?.origin_name : ""}`,
          value: value?.origin_id,
          code: value?.origin_code,
        }) : setDataCountryOrigin(null); */

        console.log({
          countryProcedencyCorrected_id,
          countryDestinyCorrected_id,
        });
        (countryProcedencyCorrected_id || countryDestinyCorrected_id) &&
          setDataCountryOrigin({
            label: `${
              code_country_des_corrected
                ? name_country_des_corrected
                  ? code_country_des_corrected +
                    " " +
                    name_country_des_corrected
                  : ""
                : ""
            }`,
            /* label: `${code_country_des
              ? code_country_des
              : code_country_des_corrected || ""
              } ${name_country_des
                ? name_country_des
                : name_country_des_corrected || ""
              }`, */
            value: countryDestinyCorrected_id,
            code: code_country_des || code_country_des_corrected,
          });

        countryPurchaseCorrected_id &&
          setDataCountryBuy({
            label:
              code_country_purs_corrected + " " + name_country_purs_corrected,
            value: countryPurchaseCorrected_id,
          });

        flagCorrected_id &&
          setValueFlag({
            label:
              code_contry_flag_corrected + " " + name_country_flag_corrected,
            value: flagCorrected_id,
          });

        packagingCorrected_id &&
          setPackagings({
            label: code_packaging_corrected + " " + name_packaging_corrected,
            value: packagingCorrected_id,
          });

        transportMethodCorrected_id &&
          setValueMethod({
            label:
              code_transportMethod_corrected +
              " " +
              name_transportMethod_corrected,
            value: transportMethodCorrected_id,
          });
        setValueSubheading(subheadingCorrected_id);
        // setSelectCurrency(response?.coin);
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingShow(false);
    }
  };

  const handleClean = () => {
    setInfo([]);
    setValueProducts(null);
    setDataCountryOrigin([]);
    setDataCountryOrigin([]);
    setValueMethod([]);
    setDataCountryBuy([]);
    setValueFlag([]);
    setPackagings([]);
    setValueSubheading([]);
    // setAcuerdos([]);
    resetItem();
    // setUnidad([]);
  };

  const handleCancel = () => {
    setstateCollapse(!stateCollapse);
    setshowItemsView(false);
    setDataToEditItems(null);
    setDataToCopyItems(null);
    handleClean();
    setquantityError([]);
  };

  const handleCancelCabecera = () => {
    setstateCollapse(true);
    setAttachment([]);
    setAttachmentDescription([]);
    setAttachmentType([]);
    setNameFiles([]);
    handleInvoice();
  };

  /* Función de validación de campos */
  const handleValidationOnSaveItem = (
    valuesItems,
    validations = [],
    additionalValidations
  ) => {
    const isError = validations.some((validation) => {
      if (!valuesItems?.[validation?.name]) {
        openAlert(`El campo ${validation?.value} es requerido`, "error");
        return true;
      }
    });
    return isError;
  };
  // Se ha agregado el operador de encadenamiento opcional (?.) para evitar errores cuando ciertos valores son nulos o indefinidos.
  const hadleSaveItems = () => {
    const dataSave = {
      ...valuesItem,
      outputFormCorrected_id: FormOutput
        ? Number(FormOutput)
        : ShowOutput
        ? Number(ShowOutput)
        : "",
      productCorrected_id: valueProducts ? valueProducts?.value : "",
      countryDestinyCorrected_id: dataCountryOrigin
        ? dataCountryOrigin?.value
        : "",
      countryOriginCorrected_id: info ? info?.value : "",
      countryPurchaseCorrected_id: dataCountryBuy ? dataCountryBuy?.value : "",
      transportMethodCorrected_id: valueMethod ? valueMethod?.value : "",
      flagCorrected_id: valueFlag ? valueFlag?.value : "",
      packagingCorrected_id: packagings ? packagings?.value : ":",
      subheadingCorrected_id: valueSubheading,
      operationCorrected_id: selectOperation?.value,

      // countryDestinyCorrected_id: 1,
      // tradeAgreementCorrected_id: acuerdos ? acuerdos?.value : "",
      added_value_us_corrected: valuesItem.added_value_us_corrected
        ? valuesItem.added_value_us_corrected
        : 0,
      refund_value_us_corrected: valuesItem.refund_value_us_corrected
        ? valuesItem.refund_value_us_corrected
        : 0,
      net_weight_corrected: valuesItem.net_weight_corrected
        ? valuesItem.net_weight_corrected
        : 0,
      gross_weight_corrected: valuesItem.gross_weight_corrected,
      exchange_rate_corrected: parseFloat(exchange_rate_corrected),
      cif_value_sdo_corrected: valuesItem.cif_value_sdo_corrected
        ? parseFloat(valuesItem.cif_value_sdo_corrected)
        : "",
      fob_sdo_value_corrected: valuesItem.fob_sdo_value_corrected
        ? parseFloat(valuesItem.fob_sdo_value_corrected)
        : "",
      product_quantity_corrected: valuesItem.product_quantity_corrected,
      quantity_corrected: valuesItem.quantity_corrected,
      number_packages_corrected: valuesItem.number_packages_corrected || 0,
      us_freight_corrected: valuesItem.us_freight_corrected,
      us_insurance_corrected: valuesItem.us_insurance_corrected,
      other_us_expenses_corrected: valuesItem.other_us_expenses_corrected,
      us_fob_value_corrected: valuesItem.us_fob_value_corrected,
      original_fob_value_us_corrected: original_fob_value_us_corrected || 0,

      cif_us_value_corrected: sumaCorrect || 0,
      cif_cop_value_corrected: multiplicacionCorrect || 0,
      cif_cop_value: valuesItem.cif_cop_value ? valuesItem.cif_cop_value : "",
      cif_us_value: valuesItem.cif_us_value ? valuesItem.cif_us_value : "",
      exchange_rate: parseFloat(exchange_rate),
      gross_weight: valuesItem.gross_weight ? valuesItem.gross_weight : "",
      other_us_expenses: valuesItem.other_us_expenses
        ? valuesItem.other_us_expenses
        : "",
      product_quantity: valuesItem.product_quantity
        ? valuesItem.product_quantity
        : "",
      quantity: valuesItem.quantity ? valuesItem.quantity : "",
      us_insurance: valuesItem.us_insurance ? valuesItem.us_insurance : "",
      us_fob_value: valuesItem.us_fob_value ? valuesItem.us_fob_value : "",
      net_weight: valuesItem.net_weight ? valuesItem.net_weight : "",
      us_freight: valuesItem.us_freight ? valuesItem.us_freight : "",

      status_item: 1,
    };
    let message;
    console.log({ dataSave });
    if (
      handleValidationOnSaveItem(dataSave, [
        {
          name: "reference_corrected",
          value: "La referencia es obligatoria",
        },
      ])
    )
      return;

    if (
      refunds?.value === "Con reintegro" &&
      !dataSave?.refund_value_us_corrected
    ) {
      return openAlert(
        "El valor a reintegrar es obligatorio para la modalidad de reintegro",
        "error"
      );
    }

    if (
      valueProducts?.initial_operation === "CDI" &&
      !dataSave?.added_value_us_corrected
    )
      return openAlert(
        "El valor agregado es obligatorio para items de cuadro de integración",
        "error"
      );

    /* Si es modalidad reintegro, validación del Valor a Reintegrar */

    if (dataToEditItems) {
      message = "Item actualizado con exito";
      CorrectItem(
        environment.UPDATE_CORRECT_ITEM_OUTPUT,
        dataSave,
        handleCancel,
        message,
        setquantityError
      );
    } else if (dataToCopyItems) {
      message = "Item copiado con exito";
      CorrectItem(
        environment.CREATE_CORRECT_ITEM_OUTPUT,
        dataSave,
        handleCancel,
        message,
        setquantityError
      );
    } else {
      message = "Item creado con exito";
      CorrectItem(
        environment.CREATE_CORRECT_ITEM_OUTPUT,
        dataSave,
        handleCancel,
        message,
        setquantityError
      );
    }
  };

  const handleFiles = (e, idFiles) => {
    e.preventDefault();
    document.getElementById(`${idFiles}`).click();
  };

  const extensionOptions = [
    ".doc",
    ".docx",
    ".pdf",
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".xls",
    ".xlsx",
    ".xlsm",
    "image/png",
    "image/*",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
  ];

  const { get: getStorage } = Storage;

  function validateFileExtension(file) {
    const getFileExtension = file.name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);
    if (!isValidExtension) {
      openAlert(
        `El archivo ${file.name} no es un tipo de archivo permitido.`,
        "error"
      );
    }
    return isValidExtension;
  }

  const extractDocumentId = (id) => {
    return Number(id.replace("document-", ""));
  };

  const handleFilesInput = async (e) => {
    try {
      e.preventDefault();
      const resModal = await onOpenUpload();
      const { name, files: uploadedFiles, id } = e.target;
      const idFile = e?.target?.id;
      const uploadData = getStorage("uploadData")?.uploadData || {};

      if (!uploadedFiles.length) return;
      if (uploadData?.cancel) return localStorage.removeItem("uploadData");

      const isValidExtension = validateFileExtension(uploadedFiles[0]);

      if (!isValidExtension) return;

      const idDocument = extractDocumentId(adjustDocumentName(idFile));
      const uploadResponse = await uploadFile(uploadedFiles[0]);

      updateFileState(uploadResponse, uploadedFiles[0]?.name, idDocument, e);
      updateNameFilesState(name);
      clearLocalStorageErrors(idDocument);
    } catch (error) {
      console.error("Error handling file input:", error);
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  };

  const updateFileState = async (uploadResponse, name, idDocument, e) => {
    const uploadData = getStorage("uploadData")?.uploadData || {};
    const { iscorrected } = await e.target.dataset;

    const file = {
      [e.target.id]: {
        file: uploadResponse,
        name,
        document_id: idDocument,
        ...uploadData,
      },
    };

    if (isObjectNotEmpty(file)) updateAndCreate(file, false, iscorrected);

    return localStorage.removeItem("uploadData");
  };

  const updateNameFilesState = (name) => {
    setNameFiles((prevNameFiles) => [...prevNameFiles, { name }]);
  };

  const clearLocalStorageErrors = (idDocument) => {
    const errors = JSON.parse(
      localStorage.getItem(`errorAttadchment-${FormOutput || ShowOutput}`) ||
        "[]"
    );
    if (errors.includes(idDocument)) {
      const filteredErrors = errors.filter((e) => e !== idDocument);
      localStorage.setItem(
        `errorAttadchment-${FormOutput || ShowOutput}`,
        JSON.stringify(filteredErrors)
      );
    }
  };

  function adjustDocumentName(name) {
    const suffix = "_doc_corrected_req";
    if (name.includes(suffix)) {
      return name.replace(suffix, "");
    }
    return name;
  }

  useEffect(() => {
    if (selectOperation === null) {
      setstateCollapse(true);
      setDataToEditItems(null);
      setDataToCopyItems(null);
      handleClean();
    }
  }, [selectOperation]);

  useEffect(() => {
    if (FormOutput || ShowOutput) {
      getItems(FormOutput || ShowOutput);
    }
  }, [FormOutput, ShowOutput, update, page, orderBy]);

  useEffect(() => {
    if (FormOutput || ShowOutput) {
      viewCommentary();
    }
  }, [FormOutput, ShowOutput, update]);

  useEffect(() => {
    const showData = dataViewFormCorrect?.data?.status?.data;

    if (isValid(showData) && isObject(showData)) {
      setselectExport({
        label: `${
          isValid(showData?.identification_num)
            ? showData?.identification_num
            : ""
        } ${showData?.business_name}`,
        value: showData?.thirdPartie_id,
      });

      setValuesHeader({
        ...showData,
        nit_business_nameCorrected: showData.identification_num,
        business_nameCorrected: showData?.business_nameCorrected,
      });

      setStateCurrentExport(
        `${
          isValid(showData?.identification_num)
            ? showData?.identification_num
            : ""
        } ${showData?.business_nameCorrected}`
      );
    }
  }, [dataViewFormCorrect]);

  useEffect(() => {
    if (!dataOperation) return;
    /* Cuando balance_correction está activo (es decir, son correcciones de saldo) */
    if (valuesHeader.balance_correction === 1) {
      const infoExporte = dataOperation
        ?.filter((data) => {
          if (
            code >= 200 &&
            code < 299 &&
            (data?.code_operation === 980 || data?.code_operation === 981)
          ) {
            return data;
          }
          if (
            code >= 400 &&
            code < 499 &&
            (data?.code_operation === 982 || data?.code_operation === 983)
          ) {
            return data;
          }
          if (
            code >= 600 &&
            code < 699 &&
            (data?.code_operation === 984 || data?.code_operation === 985)
          ) {
            return data;
          }
          if (
            code >= 800 &&
            code < 899 &&
            (data?.code_operation === 986 || data?.code_operation === 987)
          ) {
            return data;
          }
        })
        .map((item) => ({
          label: item?.code_operation + " " + item?.short_name_operation,
          value: item?.code_operation,
          id: item?.id,
          documents: item?.documents,
          plazo: item?.term,
        }));
      return setDataSelectOperation(infoExporte);
    }
    /* Cuando son correcciones normales */
    const infoExport = dataOperation
      ?.filter((data) => data?.code_operation < 900)
      .map((item) => ({
        label: item?.code_operation + " " + item?.short_name_operation,
        value: item?.code_operation,
        id: item?.id,
        documents: item?.documents,
        plazo: item?.term,
      }));
    return setDataSelectOperation(infoExport);
  }, [dataOperation]);

  // estado actual del operacion
  useEffect(() => {
    if (!(dataOperation && valuesHeader)) return;

    const idCorrected =
      operationCorrected_id || valuesHeader?.operationCorrected_id;
    const resultadoCorrect = dataOperation.find((e) => e.id === idCorrected);

    if (!resultadoCorrect) return;

    if (
      valuesHeader.balance_correction !== 1 ||
      operationCorrected_id !== valuesHeader?.operation_id
    ) {
      setselectOperation({
        label:
          resultadoCorrect?.code_operation +
          " " +
          resultadoCorrect?.short_name_operation,
        value: resultadoCorrect?.code_operation,
        id: resultadoCorrect?.id,
        documents:
          valuesHeader.balance_correction !== 1
            ? resultadoCorrect?.documents
            : DocumentOperation,
        plazo: resultadoCorrect?.term,
      });
      return setValuesHeader({
        ...valuesHeader,
        code_operationCorrected: resultadoCorrect.code_operation,
        short_name_operationCorrected: resultadoCorrect?.short_name_operation,
      });
    }
    return setselectOperation(null);
  }, [dataOperation]);
  // estado actual del operacion fin

  useEffect(() => {
    if (dataToEditItems) {
      showItems(dataToEditItems.id);
    } else if (dataToCopyItems) {
      showItems(dataToCopyItems.id);
      // getTrm();
    }
  }, [dataToEditItems, dataToCopyItems]);

  useEffect(() => {
    let isMounted = true;
    if (!stateCollapse) {
      getCountry();
      getPackaging();
      getMethodTransport();
      if (!dataToEditItems && !dataToCopyItems) {
        getTrm();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [stateCollapse]);

  useEffect(() => {
    if (dataAgreements) {
      const infoPackagin = dataAgreements.map((item) => ({
        label: item?.code_tradeAgreement + " " + item?.name_tradeAgreement,
        value: item?.id,
      }));

      setDataAgreementsNew(infoPackagin);
    }
  }, [dataAgreements]);

  useEffect(() => {
    if (dataPackagings) {
      const infoPackagin = dataPackagings.map((item) => ({
        label: item?.code_packaging + " " + item?.name_packaging,
        value: item?.id,
      }));
      setDataPackagingsNew(infoPackagin);
    }
  }, [dataPackagings]);

  useEffect(() => {
    if (dataTransportMethod) {
      const infoPackagin = dataTransportMethod.map((item) => ({
        label: item?.code_transportMethod + " " + item?.name_transportMethod,
        value: item?.id,
      }));

      setDataTransportMethodNew(infoPackagin);
    }
  }, [dataTransportMethod]);

  // const { userQualified } = useUserQualified();
  useEffect(() => {
    setSuma(
      Number(us_freight) +
        Number(us_insurance) +
        Number(other_us_expenses) +
        Number(us_fob_value)
    );

    const sumaDetodo =
      parseFloat(us_freight_corrected) +
      parseFloat(us_insurance_corrected) +
      parseFloat(other_us_expenses_corrected) +
      parseFloat(us_fob_value_corrected);

    const formateadoSumas = parseFloat(sumaDetodo);

    setSumaCorrect(formateadoSumas);
    const resultadoMultiplicacion =
      sumaDetodo * parseFloat(exchange_rate_corrected);

    const formateado = parseFloat(resultadoMultiplicacion);
    // console.log(trm);
    setMultiplicacion(suma * parseFloat(exchange_rate));
    setMultiplicacionCorrect(formateado);
  }, [valuesItem, suma, multiplicacion, sumaCorrect, multiplicacionCorrect]);

  // useEffect(() => {
  //   let resultadoProduc =
  //     product_quantity_corrected &&
  //     product_quantity_corrected?.replaceAll(",", ".");
  //   let validacionFactor = factor_conv_corrected ? factor_conv_corrected : 0;

  //   let resultadoMultiplicacion =
  //     Number(validacionFactor) * Number(resultadoProduc);
  //   let newDato = fomateo(
  //     Number(decimal_values_precision),
  //     resultadoMultiplicacion
  //   );
  //   //?.replaceAll(".", ",")
  //   if (code_businessUnit_correct === "KG") {
  //     setValuesItem({
  //       ...valuesItem,
  //       quantity_corrected: newDato,
  //       net_weight_corrected: newDato,
  //       gross_weight_corrected: newDato,
  //     });
  //   } else if (code_businessUnit_correct !== "KG") {
  //     setValuesItem({
  //       ...valuesItem,
  //       quantity_corrected: newDato,
  //     });
  //   }
  // }, [product_quantity_corrected, code_businessUnit_correct]);

  const handleMultiplicacion = () => {
    const resultadoProduc = parseFloat(product_quantity_corrected);

    const validacionFactor = parseFloat(factor_conv_corrected);

    const resultadoMultiplicacion = validacionFactor * resultadoProduc;
    const newDato = parseFloat(resultadoMultiplicacion).toFixed(2);

    // ?.replaceAll(".", ",")
    if (code_businessUnit_correct === "KG") {
      setValuesItem({
        ...valuesItem,
        quantity_corrected: newDato,
        net_weight_corrected: newDato,
        gross_weight_corrected: newDato,
      });
    } else if (code_businessUnit_correct !== "KG") {
      setValuesItem({
        ...valuesItem,
        quantity_corrected: newDato,
      });
    }
  };

  const handleBlurReference = (value) => {
    if (!value?.label) {
      setValueSubheading(null);
      setValuesItem((prev) => ({
        ...prev,
        customs_clearance_corrected: "",
        value_sdo_corrected: "",
        operation_code_corrected: "",
        initial_operation_corrected: "",
        value_sdo_product_corrected: "",
        fob_sdo_value_corrected: "",
        cif_value_sdo_corrected: "",
        code_unit_measurement_corrected: "",
        factor_conv_corrected: "",
        code_subheading_corrected: "",
        inventoryCorrected_id: "",
        reference_corrected: "",
        code_product_corrected: "",
        product_quantity_corrected: 0,
        quantity_corrected: 0,
        gross_weight_corrected: 0,
        net_weight_corrected: 0,
        other_us_expenses_corrected: 0,
        original_fob_value_us_corrected: 0,
        us_fob_value_corrected: 0,
        us_insurance_corrected: 0,
        us_freight_corrected: 0,
        number_packages_corrected: 0,
        added_value_us_corrected: "",
        refund_value_us_corrected: "",
        adjustment_justification_fob_corrected: "",
        invoice_corrected: "",
        brand_corrected: "",
        serial_corrected: "",
        output_form_item_description_corrected: "",
      }));
      setValueMethod(null);
      setInfo(null);
      setValueFlag(null);
      setDataCountryOrigin(null);
      setDataCountryBuy(null);
      setPackagings(null);
      setValueProducts([]);
      return;
    }

    /* console.log({ value }) */

    setValueSubheading(value?.subheading_id);
    setValuesItem({
      ...valuesItem,
      factor_conv_corrected: value?.convertion_factor_product,
      reference_description_corrected: value?.reference_description,
      product_unit_Correct: value?.code_unit_measurement,
      // product_description_corrected: value?.description_product,
      description_subheading_corrected: value?.description,
      code_subheading_corrected: value?.code_subheading,
      code_businessUnit_correct: value?.businnesUnitSubheading,
      code_businessUnit: value?.businnesUnitSubheading,

      customs_clearance_corrected: value.customs_clearance
        ? value?.customs_clearance
        : "",
      value_sdo_corrected: value.value_sdo ? value?.value_sdo : "",
      operation_code_corrected: value.operation_code
        ? value?.operation_code
        : "",
      initial_operation_corrected: value.initial_operation
        ? value?.initial_operation
        : "",
      code_product_corrected: value.code_product ? value?.code_product : "",
      code_unit_measurement_corrected: value.code_unit_measurement
        ? value?.code_unit_measurement
        : "",
      value_sdo_product_corrected: value.value_sdo_product
        ? value?.value_sdo_product
        : "",
      fob_sdo_value_corrected: value.fob_sdo_value ? value?.fob_sdo_value : "",
      cif_value_sdo_corrected: value.cif_value_sdo ? value?.cif_value_sdo : "",
      inventoryCorrected_id: value?.inventory_id || "",
      reference_corrected: value?.reference_description,
    });

    /*
    {
      label: `${value?.destiny_name ? (value?.destiny_code ? value?.destiny_code + " " : "") + value?.destiny_name : ""}`,
      value: value?.destiny_id,
    }
    */

    value?.destiny_id
      ? setInfo({
          label: `${
            value?.destiny_name
              ? (value?.destiny_code ? value?.destiny_code + " " : "") +
                value?.destiny_name
              : ""
          }`,
          value: value?.destiny_id,
        })
      : setInfo(null);

    value?.flag_id
      ? setValueFlag({
          label: `${
            value?.flag_name
              ? (value?.flag_code ? value?.flag_code + " " : "") +
                value?.flag_name
              : ""
          }`,
          value: value?.flag_id,
        })
      : setValueFlag(null);

    value?.origin_id
      ? setDataCountryOrigin({
          label: `${
            value?.origin_name
              ? (value?.origin_code ? value?.origin_code + " " : "") +
                value?.origin_name
              : ""
          }`,
          value: value?.origin_id,
          code: value?.origin_code,
        })
      : setDataCountryOrigin(null);

    value?.purchase_id
      ? setDataCountryBuy({
          label: `${
            value?.purchase_name
              ? (value?.purchase_code ? value?.purchase_code + " " : "") +
                value?.purchase_name
              : ""
          }`,
          value: value?.purchase_id,
        })
      : setDataCountryBuy(null);
  };

  useEffect(() => {
    if (FormOutput || ShowOutput) viewCommentary();
  }, [newCommentary]);

  const divCommentary = document.querySelector(".chakra-modal__body");
  const handleScroll = () => {
    divCommentary.scrollTop = "9999";
  };

  const viewCommentary = async () => {
    try {
      const res = await __CommentaryForm(
        token,
        FormOutput ? Number(FormOutput) : ShowOutput ? Number(ShowOutput) : "",
        environment.CORRECT_GET_COMMENT_FMM_0UTPUT
      );
      setComentarios(res?.data?.status?.data);
      handleScroll();
    } catch (error) {}
  };

  const handleChangeRecuperar = (id) => {
    const infoEntryCorrect = {
      id,
      status_outputFormCorrected: "BORRADOR",
      retrieved: "RECUPERADO",
    };

    changeStatusCorregido(infoEntryCorrect);
  };

  const handleInvoice = () => {
    const formData = new FormData();
    formData.append("id", FormOutput);
    formData.append(
      "commercial_invoice_corrected",
      valuesHeader.commercial_invoice_corrected && valuesItem.invoice_corrected
        ? valuesHeader.commercial_invoice_corrected +
            "," +
            valuesItem.invoice_corrected
        : !valuesHeader.commercial_invoice_corrected &&
          valuesItem.invoice_corrected
        ? valuesItem.invoice_corrected
        : ""
    );
    const resultado = valuesHeader.commercial_invoice_corrected
      ? valuesHeader.commercial_invoice_corrected.split(",")
      : [];

    if (
      valuesItem.invoice_corrected &&
      !resultado.includes(valuesItem.invoice_corrected)
    ) {
      updateCorrectDocument(
        environment.CORRECT_FMMO_UPDATE,
        formData,
        handleUpdate,
        "/FormCorrectionOutput?FormOutput"
      );
    }
  };

  const handleUpdate = () => {
    setUpdate(!update);
  };
  const { authUser } = useContext(AuthContext);

  const isAuthorizationDeletedDocument =
    status_outputFormCorrected !== "BORRADOR" && is_commerce === 1;

  const [correctedDataTypeOperation, setCorrectedTypeOperation] =
    useState(null);

  const getOperation = async (id) => {
    const AuthorizationId = authUser.is_qualified_user !== 1 ? id : "";
    try {
      const res = await __OperationListCorrected(token, id);
      const data = res?.data?.status?.data;
      const infoExport = data.map((item) => ({
        label: item?.code_operation + " " + item?.short_name_operation,
        value: item?.code_operation,
        id: item?.id,
        documents: item?.documents,
        plazo: item?.term,
      }));
      setCorrectedTypeOperation(infoExport);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (
      (valuesHeader?.balance_correction === 0 && idQualified) ||
      idQualified
    ) {
      getOperation(idQualified);
    }
  }, [valuesHeader?.balance_correction, idQualified]);

  useEffect(() => {
    if (FormOutput) {
      return async () => {
        const body = new FormData();
        body.append("form_id", FormOutput);
        body.append("type", 0);
        body.append("type_of", "FMMSC");
        const res = await __Post(environment.FORMS_ON_EDIT, token, body);
      };
    }
  }, [FormOutput]);

  useEffect(() => {
    return () => {
      setstateCollapse(true);
    };
  }, []);

  const { blockForm, formLockQueryResult } = useFormBlocker({
    form_id: FormOutput || "",
    type_of: "FMMSC",
    userProvidedEndpoint: environment.FORMS_ON_EDIT,
  });

  useEffect(() => {
    blockForm(1);
  }, []);

  useEffect(() => {
    if (formLockQueryResult?.code === 400) {
      openAlert(formLockQueryResult?.message, "error");
      navigate(-1);
    }
  }, [formLockQueryResult]);

  const refetchForm = (flag, form_id, new_status, dataCurrent) => {
    const shouldRefetch =
      flag === "output_form_corrected" &&
      new_status !== dataCurrent?.status_outputFormCorrected &&
      new_status !== "REVISADO" &&
      form_id === dataCurrent?.id;

    if (shouldRefetch) {
      queryClient.invalidateQueries({ queryKey: ["viewFormCorrect"] });
      getItems(FormOutput || ShowOutput);

      return;
    }
  };

  useStatusListener({
    id: FormOutput || ShowOutput,
    callBack: refetchForm,
    dataCurrent: valuesHeader,
  });

  const { nameDocument } = useGlobalContext();

  const cleanFile = (file, doc_corrected_req, typeDoc) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${
        file?.attachment_name ??
        file?.name_attachment ??
        file?.other_attachment_name
      }"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const IsTypeNewFile =
          doc_corrected_req === 1 ? "/doc_corrected_req" : "";

        const requestData = {
          Endpoint:
            typeDoc === "attachment"
              ? environment.DELETE_ATTACHMENT_OUTPUT_CORRECT
              : environment.DELETE_OTHER_ATTACHMENT_OUTPUT_CORRECT,
          resourceIdentifier: `${file?.id}${IsTypeNewFile}`,
        };

        mutate(requestData, {
          onSuccess: (res) => responseDeleteFile(res),
        });
      }
    });
  };

  return (
    <>
      <Commentary
        id={
          FormOutput ? Number(FormOutput) : ShowOutput ? Number(ShowOutput) : ""
        }
        ruta={environment.CORRECT_CREATE_COMMENT_FMM_OUTPUT}
        create={createCommentary}
        commentarys={commentarios}
      />
      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar item n. ${dataToDelete?.item_corrected}`}
        onDelete={deleteItemsCorrect}
      />

      <ModalGeneric
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        onOpen={onOpenUpload}
        title={`Cargar documento ${nameDocument && nameDocument}`}
        size={"lg"}
      >
        <GroupInputUpload onClose={onCloseUpload} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenRecuperar}
        onOpen={onOpenRecuperar}
        onClose={onCloseRecuperar}
        title={`Recuperar formulario`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea recuperar el formulario{" "}
          {dataRecuperar?.code_entryFormCorrected ||
            dataRecuperar?.code_outputFormCorrected}
          ?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              El documento regresara al estado borrador.
            </SubTitle>
          </div>
        </div>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseRecuperar}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() => handleChangeRecuperar(dataRecuperar?.id)}
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      {valuesHeader && (
        <ExportPdfOutputCorrect
          id="IdPdfFmmCorrectEntry"
          values={valuesHeader}
          items={currentPage}
        />
      )}

      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div className=" mb-5">
            <HeaderForm
              status={status_outputFormCorrected}
              numberForm={code_outputFormCorrected}
              UserQualified={name_qualified}
              Modulo={`${"Formulario"} de ${
                valuesHeader.balance_correction === 1 ? "ajuste" : "corrección"
              } de ${
                valuesHeader.balance_correction === 1 ? "saldos en" : ""
              } salidas de mercancías en Zonas Francas`}
            />
          </div>
          {ShowOutput && (
            <ButtonView data={valuesHeader} idFmm={FormOutput || ShowOutput}>
              <CreateAt
                entries={valuesHeader?.users}
                config={{
                  statusKey: "status_outputFormCorrected",
                  dateKey: "date_outputFormCorrected",
                  timeKey: "time_outputFormCorrected",
                  personNameKey: "person_name",
                }}
                expirationDate={valuesHeader?.expirationDate}
              />
            </ButtonView>
          )}

          <div className="row">
            <div className="col-md-6">
              <HeaderSearch>
                <SubTitleHeader>
                  Salida
                  <NavLink
                    to={`/FormShowExit?ExitNumber=${valuesHeader?.outputForm_id}`}
                    className="ms-2"
                  >
                    {valuesHeader?.code_outputForm}
                  </NavLink>
                </SubTitleHeader>
                <FmmOHeader data={valuesHeader} />
              </HeaderSearch>
            </div>

            <div className="col-md-6">
              <HeaderSearch>
                <SubTitleHeader>
                  {valuesHeader.balance_correction === 1
                    ? "Ajuste"
                    : "Corrección"}
                </SubTitleHeader>
                {FormOutput && (
                  <FmmOheadercorrected
                    handleChange={handleInputChangeHeader}
                    data={valuesHeader}
                    dataCorrected={correctedDataTypeOperation}
                    balanceCorrection={valuesHeader?.balance_correction}
                  />
                )}
                {ShowOutput && <FmmOheadercorrectedShow data={valuesHeader} />}
              </HeaderSearch>
            </div>

            <div className="col-md-6">
              <HeaderSearch style={{ marginTop: "40px" }}>
                <div>
                  <TitleOperationOutput code={code} />
                </div>
                <div className="p-1" style={{ background: "#f2f2f2" }}>
                  <CapsulaContainerOutput
                    code={code}
                    valuesHeader={valuesHeader}
                  />
                </div>
              </HeaderSearch>
            </div>

            <div className="col-md-6">
              <HeaderSearch style={{ marginTop: "40px" }}>
                <div>
                  <TitleOperationOutput
                    code={
                      valuesHeader.balance_correction === 1
                        ? code
                        : selectOperation?.value
                    }
                  />
                </div>
                <div className="p-1" style={{ background: "#f2f2f2" }}>
                  {valuesHeader.balance_correction !== 1 && (
                    <>
                      <span>
                        <Text as="sub">* Opcional</Text>
                        <br />
                      </span>
                      <RadioCheck
                        set={setOpcional}
                        value={opcional}
                        item={dataDUTA}
                        disables={ShowOutput}
                        diff={opcionalCurrent !== opcional}
                      />
                    </>
                  )}
                  {selectOperation?.value === 50 ? (
                    <NationalTerritoryCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      defaultDisabless={ShowOutput}
                    />
                  ) : selectOperation?.value >= 201 &&
                    selectOperation?.value <= 299 ? (
                    <RestOfTheWorldCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      defaultDisabless={ShowOutput}
                    />
                  ) : selectOperation?.value >= 401 &&
                    selectOperation?.value <= 499 ? (
                    <NationalTerritoryCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      defaultDisabless={ShowOutput}
                    />
                  ) : selectOperation?.value >= 601 &&
                    selectOperation?.value <= 699 ? (
                    <RestOfTheWorldCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      defaultDisabless={ShowOutput}
                    />
                  ) : selectOperation?.value >= 801 &&
                    selectOperation?.value <= 899 ? (
                    <UserToUserCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      StateDisble={ShowOutput}
                      // dataCorrect={valuesCorrect}
                    />
                  ) : valuesHeader.balance_correction === 1 ? (
                    <CapsulaContainerOutput
                      code={code}
                      valuesHeader={valuesHeader}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </HeaderSearch>
            </div>

            <div className="col-4">
              <Text className="mt-4" fontSize="xl" style={{ fontWeight: 600 }}>
                Ítems
              </Text>
            </div>
            <div className="col-4"></div>

            <div className="col-4">
              <div className="d-flex float-end my-4">
                {!ShowOutput && valuesHeader.balance_correction !== 1 && (
                  <BtnWithToltip
                    msgToltip="Agregar ítem"
                    hableSubmit={() => setstateCollapse(!stateCollapse)}
                    icon={CgPlayListAdd}
                  />
                )}
              </div>
            </div>

            {!stateCollapse && (
              <div className="col-6">
                {isLoadingShow && <SpinnerComponent />}
                {!isLoadingShow && (
                  <div className="p-1" style={{ background: "#f2f2f2" }}>
                    <FmmItemOriginal
                      data={valuesItem}
                      suma={suma}
                      multiplicacion={multiplicacion}
                      sumaCorregida={sumaCorrect}
                      multiplicacionCorregida={multiplicacionCorrect}
                    />
                  </div>
                )}
              </div>
            )}
            {!stateCollapse && (
              <div className="col-6">
                {isLoadingShow && <SpinnerComponent />}
                {!isLoadingShow && (
                  <div className="p-1" style={{ background: "#f2f2f2" }}>
                    <FmmItemCorregido
                      data={valuesItem}
                      suma={sumaCorrect}
                      selectOperation={selectOperation}
                      multiplicacion={multiplicacionCorrect}
                      handleInputChange={handleInputChangeItem}
                      valuesItem={valuesItem}
                      setValuesItem={setValuesItem}
                      sumaOriginal={suma}
                      multiplicacionOriginal={multiplicacion}
                      hadleSaveItems={hadleSaveItems}
                      handleCancel={handleCancel}
                      idUsuarioCalificados={valuesHeader.qualifiedUser_id}
                      codeOriginalOpe={code}
                      setquantityError={setquantityError}
                      quantityError={quantityError}
                      flagBalance={valuesHeader.balance_correction}
                      handleBlurReference={handleBlurReference}
                      handleMultiplicacion={handleMultiplicacion}
                      valuesHeader={valuesHeader}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="col-12 ">
              <div className="mt-2">
                <InputSelect
                  data={[
                    { value: "ASC", label: "Ascendente" },
                    { value: "DESC", label: "Descendente" },
                  ]}
                  value={orderBy}
                  name="order_by"
                  set={(e) => {
                    setOrderBy(e);
                    setPage(1);
                  }}
                  placeholder="Ordenar por"
                  inputAttrs={{
                    color: "#5f5f",
                  }}
                  size={30}
                  isClearable={false}
                />
                <TablePass
                  thead={!ShowOutput ? TitleItemFmm : TitleItemFmmShow}
                  data={currentPage}
                  setData={setCurrentPage}
                  loading={loadingItems}
                  tbodyData={
                    !ShowOutput ? TBodyItemCorrect : TBodyItemCorrectShow
                  }
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  loading={loadingItems}
                />
              </div>
            </div>

            <div className="col-12">
              <HeaderSearch>
                <FileUploader
                  associatedDocumentType={DocumentOperation}
                  cleanFile={cleanFile}
                  onUploadFile={onUploadFile}
                  files={listFiles}
                  typeDoc={"attachment"}
                />
              </HeaderSearch>
              <HeaderSearch>
                <Box display={"grid"} gap={4} pt={12}>
                  <EnhancedFileUploadManager
                    title="Otros documentos"
                    onUploadComplete={() => {}}
                    handleResponseUpdateForm={handleResponseUpdateForm}
                    endpointAttachments={{
                      create: environment.CORRECT_FMMO_UPDATE_DOCUMENT,
                    }}
                    postPropertyName={"other_attachment"}
                    vaporBaseUrl={environment.VAPOR_BASE_URL}
                    getAuthToken={() => {
                      return token;
                    }}
                    formID={FormOutput || ShowOutput}
                  />

                  <ExistingFiles
                    existingFiles={otherFiles?.other_files}
                    handleDeleteFile={cleanFile}
                  />
                </Box>
              </HeaderSearch>
            </div>

            {!ShowOutput && (
              <Box
                display="flex"
                alignItems="center"
                marginTop={"20px"}
                marginBottom={"20px"}
                justifyContent="flex-end"
                flexWrap={"wrap"}
              >
                <ButtonStyled type="submit" onClick={() => handleSubmit("")}>
                  Guardar
                </ButtonStyled>
              </Box>
            )}
          </div>
        </>
      )}
    </>
  );
};
