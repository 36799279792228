import Select from "react-select";
import { LabelStyled } from "components/addProduct/CreateTemplate.styled";

export const SubPartidaMulti = ({ data, tag, set, valor }) => {
  const selectValor = (optionesVa) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element.code_subheading,
        value: element.id
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    const documentlabel = [];
    for (let i = 0; i < items.length; i++) {
      const datos = data.find((info) => info.id === items[i]?.value);
      documentlabel.push({ label: datos.code_subheading, value: datos.id });
    }
    set(documentlabel);
  };

  return (
    <>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        closeMenuOnSelect={false}
        value={valor}
        isMulti
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </>
  );
};
