/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Flex, Spacer } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import "./stilos.css";
import { useForm } from "hooks/useForm";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import InputSelect from "components/InputSelect/InputSelect";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useSelector } from "react-redux";
import { __SelectAcuerdosSub } from "request/configuration/__ASubheading";
import { SubPartidaMulti } from "components/SelectInput/SelectInputMulti";
import { AuthContext } from "context/AuthContext";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { __PostForm } from "request/Petitions/__Post";
import environment from "constants/apiConst";

export const ExporByColumn = ({
  onClose,
  ListLeft,
  listRight,
  handleSubmit,
  listThree,
  statusDataSelect,
  dafaulActive = [],
  existingOrder
}) => {
  const allOrOne = ["Todos los usuarios", "Usuario"];
  const { authUser } = useContext(AuthContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenUser, onOpenUser, onCloseUser] = useModal();
  const [userQualified, setUserQuialified] = useState([]);
  const [subpartidaSelecc, setSubpartidaSelecc] = useState([]);
  const [subpartidaAplly, setSubpartidaAplly] = useState([]);
  const [dataUserPesaje, setDataUserPesaje] = useState([]);

  const [valueQualified, setValueQuialified] = useState([]);
  const [valueOrder, setValueOrder] = useState([]);
  const [valueFormType, setValueFormType] = useState([]);
  const [stateDispacthOrPass, setStateDispacthOrPass] = useState(null);
  const [valueUserPesaje, setValueUserPesaje] = useState(null);

  // estados de los select
  const [userQualifiedState, setUserQualifiedState] = useState(false);
  const [stateCustoms, setStateCustoms] = useState(false);
  const [Subpartidas, setSubpartidas] = useState(false);
  const [UserWeigh, setUserWeigh] = useState(false);
  const [formType, setFormType] = useState(false);
  const [rangeDate, setRangeDate] = useState(false);
  const [numForm, setNumForm] = useState(false);
  const [consecutives, setConsecutives] = useState(false);
  const [vehiclePlates, setVehiclePlates] = useState(false);
  const [orderDate, setOrderDate] = useState(false);

  const [values, handleInputChange, reset, setValues] = useForm({});
  const [form, handleInputChangeForm, resetForm, setForm] = useForm({
    date_1: "",
    date_2: "",
    nickname: "",
    formNumber: "",
    consecutive: "",
    vehiclePlate: ""
  });
  const { date_1, date_2, formNumber, consecutive, vehiclePlate } = form;

  useEffect(() => {
    getUserQualified();
    getAllSubpartida();
    getUserPesaje();
  }, []);

  const DataOrder = [
    { value: "asc", label: "Ascedente" },
    { value: "desc", label: "Desendente" }
  ];

  const DataFormType = [
    { value: 1, label: "Despacho" },
    { value: 2, label: "Entrada" }
  ];

  const selectVar = (e) => {
    const { checked, value, id } = e.target;

    if (checked) {
      if (
        value === "containers" ||
        value === "formularios" ||
        value === "nickname" ||
        value === "status"
      ) {
        setForm({
          ...form,
          [id]: Array.isArray(value) ? value : id
        });
      } else {
        setValues({
          ...values,
          [id]: Array.isArray(value) ? value : id
        });
      }
    } else {
      if (
        value === "containers" ||
        value === "formularios" ||
        value === "nickname") {
        const objs = Object.assign({}, { ...form });
        delete objs[id];
        setForm(objs);
      }
      const obj = Object.assign({}, { ...values });
      delete obj[id];
      setValues(obj);
    }

    if (
      e.target.id === "name_qualified" &&
      checked &&
      !authUser.is_qualified_user
    ) {
      onOpenUser();
    } else if (id === "name_qualified" && !checked) {
      setValueQuialified([]);
      setUserQualifiedState(false);
    } else if (id === "status" && checked) {
      setStateCustoms(true);
    } else if (id === "status" && !checked) {
      setStateDispacthOrPass([]);
      setStateCustoms(false);
    } else if (id === "codeSubheading" && checked) {
      setSubpartidas(true);
    } else if (id === "codeSubheading" && !checked) {
      setSubpartidas(false);
      setSubpartidaAplly([]);
    } else if (id === "date" && checked) {
      setRangeDate(true);
    } else if (id === "date" && !checked) {
      setRangeDate(false);
      setForm({ ...form, date_1: "", date_2: "" });
    } else if (id === "order" && checked) {
      setOrderDate(true);
    } else if (id === "order" && !checked) {
      setValueOrder([]);
      setOrderDate(false);
    } else if (id === "formType" && checked) {
      setFormType(true);
    } else if (id === "formType" && !checked) {
      setFormType(false);
    } else if (id === "formNumber" && checked) {
      setNumForm(true);
    } else if (id === "formNumber" && !checked) {
      setNumForm(false);
      setForm({ ...form, formNumber: "" });
    } else if (id === "consecutive" && checked) {
      setConsecutives(true);
    } else if (id === "consecutive" && !checked) {
      setConsecutives(false);
      setForm({ ...form, consecutive: "" });
    } else if (id === "vehiclePlate" && checked) {
      setVehiclePlates(true);
    } else if (id === "vehiclePlate" && !checked) {
      setVehiclePlates(false);
      setForm({ ...form, vehiclePlate: "" });
    } else if (id === "userManagment" && checked) {
      setUserWeigh(true);
    } else if (id === "userManagment" && !checked) {
      setUserWeigh(false);
      setValueUserPesaje(null);
    }
  };

  const handleChange = (e) => {
    onCloseUser();
    const definicion = e.target.value;
    if (definicion === "Todos los usuarios") {
      setUserQualifiedState(false);
    } else {
      setUserQualifiedState(true);
    }
  };

  const getUserQualified = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const infoUsuarios = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified
      }));
      setUserQuialified(infoUsuarios);
    } catch (error) {}
  };

  const getUserPesaje = async () => {
    try {
      const res = await __PostForm(environment.TREASURY_WEIGHING_USER, token);
      const infoUsuariosPesajes = res?.data?.status?.data.map((item) => ({
        value: item?.user_id,
        label: item?.nickname
      }));
      setDataUserPesaje(infoUsuariosPesajes);
    } catch (error) {}
  };

  const getAllSubpartida = async () => {
    try {
      const res = await __SelectAcuerdosSub(token);
      setSubpartidaSelecc(res?.data?.status?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (dafaulActive) {
      let objeto = {};
      dafaulActive.forEach((items) => (objeto = { ...objeto, [items]: items }));
      setValues((prev) => ({ ...prev, ...objeto }));
    }
  }, [dafaulActive]);

  const handlePreview = () => {
    const subpartidaId = [];

    subpartidaAplly?.forEach(({ value }) => subpartidaId?.push(value));
    const data = {
      ...form,
      qualifiedUser_id: valueQualified?.length !== 0 ? valueQualified?.value : "",
      subheading_id: subpartidaId,
      status: stateDispacthOrPass ? stateDispacthOrPass?.value : "",
      formType: valueFormType?.length !== 0 ? valueFormType?.value : "",
      userManagment: valueUserPesaje !== null ? valueUserPesaje?.value : ""
    };

    if (existingOrder) {
      handleSubmit(
        values,
        data,
        valueOrder?.length !== 0 ? valueOrder?.value : "desc"
      );
    } else {
      handleSubmit(values, data);
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenUser}
        onOpen={onOpenUser}
        onClose={onCloseUser}
        title="Seleccione una opción"
        size={"xs"}
      >
        <div className="d-flex justify-content-center p-2 mb-4">
          {allOrOne &&
            allOrOne.map((items, index) => (
              <Checkbox
                key={index}
                id={items}
                value={items}
                className="m-1"
                onChange={handleChange}
              >
                {items}
              </Checkbox>
            ))}
        </div>
      </ModalGeneric>
      {ListLeft || listRight || listThree
        ? (
          <div className="p-2">
            <h1 className="mb-3">Seleccione las columnas a exportar:</h1>
            <div className="row">
              <div className="col">
                <ul className="noStyle">
                  {ListLeft &&
                  ListLeft.map((items, index) => (
                    <li key={index}>
                      <Checkbox
                        id={items.name}
                        value={items.value}
                        className="ms-1 mb-2 check-box"
                        onChange={selectVar}
                        defaultChecked={
                          dafaulActive &&
                          dafaulActive.find((element) =>
                            element === items.value
                          )
                        }
                      >
                        <span className="name-label">{items.label}</span>
                      </Checkbox>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col">
                <ul className="noStyle">
                  {listRight &&
                  listRight.map((items, index) => (
                    <li key={index}>
                      <Checkbox
                        id={items.name}
                        value={items.value}
                        className="mb-2 check-box"
                        onChange={selectVar}
                        defaultChecked={
                          dafaulActive &&
                          dafaulActive.find((element) =>
                            element === items.value
                          )
                        }
                      >
                        <span className="name-label">{items.label}</span>
                      </Checkbox>
                    </li>
                  ))}
                </ul>
              </div>

              {listThree && listThree.length !== 0 && (
                <div className="col">
                  <ul className="noStyle">
                    {listThree.length !== 0 &&
                    listThree.map((items, index) => (
                      <li key={index}>
                        <Checkbox
                          id={items.name}
                          value={items.value}
                          className="mb-2"
                          onChange={selectVar}
                        >
                          {items.label}
                        </Checkbox>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {userQualifiedState && (
              <div style={{ width: "100%" }}>
                <InputSelect
                  tag={"Usuario calificado"}
                  data={userQualified}
                  set={setValueQuialified}
                  value={valueQualified}
                  placeholder=""
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "2rem"
              }}
            >
              {stateCustoms && (
                <div style={{ width: `45%` }}>
                  <InputSelect
                    name={"state_dispacth"}
                    tag={"Estado"}
                    data={statusDataSelect || []}
                    set={setStateDispacthOrPass}
                    value={stateDispacthOrPass}
                  />
                </div>
              )}

              {Subpartidas && (
                <div style={{ width: "45%" }}>
                  <SubPartidaMulti
                    data={subpartidaSelecc}
                    tag={"Subpartida"}
                    set={setSubpartidaAplly}
                    valor={subpartidaAplly}
                  />
                </div>
              )}
            </div>
          </div>
        )
        : (
          <></>
        )}

      {rangeDate && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <LabelGroup
            tipo={"date"}
            name={"date_1"}
            onChange={handleInputChangeForm}
            value={date_1}
            tag={"Fecha inicio"}
            size={45}
            desicion={false}
          />
          <LabelGroup
            tipo={"date"}
            name={"date_2"}
            onChange={handleInputChangeForm}
            value={date_2}
            tag={"Fecha final"}
            size={45}
            desicion={false}
          />
        </div>
      )}

      {numForm || consecutives
        ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem"
            }}
          >
            {numForm && (
              <LabelGroup
                tipo={"text"}
                name={"formNumber"}
                onChange={handleInputChangeForm}
                value={formNumber}
                tag={"Número de pase"}
                size={45}
                desicion={false}
              />
            )}

            {consecutives && (
              <LabelGroup
                tipo={"text"}
                name={"consecutive"}
                onChange={handleInputChangeForm}
                value={consecutive}
                tag={"Consecutivo"}
                size={45}
                desicion={false}
              />
            )}
          </div>
        )
        : (
          <></>
        )}

      {vehiclePlates || formType
        ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem"
            }}
          >
            {vehiclePlates && (
              <LabelGroup
                tipo={"text"}
                name={"vehiclePlate"}
                onChange={handleInputChangeForm}
                value={vehiclePlate}
                tag={"Placa del vehículo"}
                size={45}
                desicion={false}
              />
            )}

            {formType && (
              <div style={{ width: "45%" }}>
                <InputSelect
                  tag={"Tipo de Operación"}
                  data={DataFormType}
                  set={setValueFormType}
                  value={valueFormType}
                />
              </div>
            )}
          </div>
        )
        : (
          <></>
        )}

      {orderDate || UserWeigh
        ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem"
            }}
          >
            {orderDate && (
              <div style={{ width: "45%" }}>
                <InputSelect
                  tag={"Orden"}
                  data={DataOrder}
                  set={setValueOrder}
                  value={valueOrder}
                />
              </div>
            )}

            {UserWeigh && (
              <div style={{ width: "45%" }}>
                <InputSelect
                  tag={"Usuarios"}
                  data={dataUserPesaje}
                  set={setValueUserPesaje}
                  value={valueUserPesaje}
                />
              </div>
            )}
          </div>
        )
        : (
          <></>
        )}

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => handlePreview()}
          isDisabled={Object.keys(values).length < 3}
        >
          Exportar
        </ButtonStyled>
      </Flex>
    </>
  );
};
