import React, { useContext } from "react";
import { Box, Flex, Spacer, Stack, Tooltip } from "@chakra-ui/react";
import environment from "constants/apiConst";
import ProductCRUDContext from "context/ProductCRUDContext";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { Title } from "styled/Title";
import { HiOutlinePlus } from "react-icons/hi";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { CargaMasiva } from "pages/Terceros/carga masiva/CargaMasiva";
import { MdUploadFile } from "react-icons/md";
import { ModalGeneric } from "components/modal/ModalGeneric";

const ProductoInterSubtitle = ({
  title = "",
  openModalNP,
  state,
  setCreate,
  allAccess,
  myPermission,
  BulkLoad,
  onOpenExport
}) => {
  const { onOpenEdit, isOpenCarga, onOpenCarga, onCloseCarga } =
    useContext(ProductCRUDContext);

  const clickProduct = () => {
    setCreate(environment.CREATE_PRODUCT_INT);
    onOpenEdit();
  };

  const clickProcess = () => {
    openModalNP();
    setCreate(environment.CREATE_PROCESO);
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenCarga}
        onOpen={onOpenCarga}
        onClose={onCloseCarga}
        title={"Carga masiva"}
        buttonClose={false}
      >
        <CargaMasiva onClose={onCloseCarga} BulkLoad={BulkLoad} endpoint={environment.IMPORT_PRODUCT_INT} type={'Productos'}/>
      </ModalGeneric>

      <Box>
        <Flex>
          <Title className="mt-3">{title}</Title>
          <Spacer />
          <Stack spacing={1} direction="row" align="center">
            {(allAccess === true ||
              (myPermission?.includes("createProduct") && state === true)) &&
            state === true
              ? (
                <>
                  <Tooltip placement="top" hasArrow label="Cargar Masivamente">
                    <ButtonHeaderModule
                      onClick={onOpenCarga}
                      bgColor="#1F7AC3"
                      className="me-4 align-bottom d-flex float-end"
                    >
                      <IconGeneric color="#fff" as={MdUploadFile} />
                    </ButtonHeaderModule>
                  </Tooltip>

                  <Tooltip placement="top" hasArrow label="Agregar Producto">
                    <span>
                      <ButtonHeaderModule
                        onClick={clickProduct}
                        bgColor="#05668e"
                        className="align-bottom d-flex float-end"
                      >
                        <IconGeneric color="#fff" as={HiOutlinePlus} />
                      </ButtonHeaderModule>
                    </span>
                  </Tooltip>
                  <ExportDetail onOpen={onOpenExport} />
                </>
              )
              : (allAccess === true ||
                myPermission?.includes("createProductiveProces")) &&
              state === false
                ? (
                  <>
                    <Tooltip placement="top" hasArrow label="Crear nueva plantilla">
                      <span>
                        <ButtonHeaderModule
                          onClick={clickProcess}
                          bgColor="#f9a621"
                          className="align-bottom d-flex float-end "
                        >
                          <IconGeneric color="#fff" as={HiOutlinePlus} />
                        </ButtonHeaderModule>
                      </span>
                    </Tooltip>
                    <ExportDetail onOpen={onOpenExport} />
                  </>
                )
                : (
                  ""
                )}
          </Stack>
        </Flex>
      </Box>
    </>
  );
};

export default ProductoInterSubtitle;
