import React, { useCallback, useEffect, useState } from 'react'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import {
  CardWeighing,
  DivIconCardWeighing,
  IconCardWeighing,
  CardWeighingContainer,
  CardHeader,
  CardContent,
  CardFooter,
  DivContentCard
} from './Style/CardWeighing'
import { FaWeight } from 'react-icons/fa'
import './Style/Style.css'
import { HeaderWeighinhMachine } from './FormWeighingMachine.js/HeaderWeighinhMachine'
import { useSelector } from 'react-redux'
import environment from 'constants/apiConst'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { FormAddWeighinhMachine } from './FormWeighingMachine.js/FormAddWeighinhMachine'
import { useModal } from 'hooks/useModal'
import FormViewWeighin from './view/FormViewWeighin'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __GetData, __GetDataId } from 'request/Petitions/__Get'
import { ConfigBascula } from './ConfigBascula'
import { useForm } from 'hooks/useForm'
import { Box, List } from '@chakra-ui/react'
import { CardWeighingNew } from './CardWeighing/CardWeighing'
import { ListConfigBascula } from './List'
import Swal from 'sweetalert2'

export const WeighingMachine = () => {
  const [isOpenAdd, onOpenAdd, onCloseAdd] = useModal()
  const [isOpenView, onOpenView, onCloseView] = useModal()
  const [openAlert] = AlertErrorAlert()
  const [isOpenList, onOpenList, onCloseList] = useModal()
  const [isOpenConfig, onOpenConfig, onCloseConfig] = useModal()

  const [dataBasculas, setDataBasculas] = useState([])
  const [update, setUpdate] = useState(true)
  const [StatusEdit, setStatusEdit] = useState(false)
  const [dataToEdit, setDataToedit] = useState(null)
  const [dataToshow, setDataToshow] = useState(null)

  const { token, freeZone_id } = useSelector(state => state.auth.dataUser)

  const getBasculas = useCallback(async () => {
    try {
      const res = await __GetData(environment.GET_WEIGHING, token)
      setDataBasculas(res?.data?.status?.data)
    } catch (error) {
      console.log('WeighingMachine', error)
    }
  }, [token])

  useEffect(() => {
    getBasculas()
  }, [update, getBasculas, freeZone_id])

  const handleDelete = async (id, name) => {
    try {
      Swal.fire({
        title: `¿Estás seguro de eliminar ${name.bold() || ''}?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#1F7AC3',
        confirmButtonText: 'Eliminar',
        customClass: {
          title: 'class-text'
        }
      }).then(async result => {
        if (result.isConfirmed) {
          const data = await __GetDataId(environment.DELETE_WEIGHING, token, id)
          if (data?.data?.status?.code === 200) {
            openAlert(`Bascula Eliminada exitoxamente`, 'success')
            setUpdate(!update)
          } else {
            openAlert(`Hubo un error`, 'error')
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = item => {
    setDataToedit(item)
    setStatusEdit(true)
    onOpenAdd()
  }

  const handleView = item => {
    setDataToshow(item)
    onOpenView()
  }

  const [selectpesaje, setselectpesaje] = useState(null)

  return (
    <>
      <ModalGeneric
        isOpen={isOpenAdd}
        onOpen={onOpenAdd}
        onClose={onCloseAdd}
        buttonClose={false}
        title={StatusEdit ? 'Actualizar báscula' : 'Agregar nueva báscula'}
        size='xl'
      >
        <FormAddWeighinhMachine
          dataToEdit={dataToEdit}
          setDataToedit={setDataToedit}
          onCloseAdd={onCloseAdd}
          setUpdate={setUpdate}
          Status={StatusEdit}
          setStatus={setStatusEdit}
          update={update}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenView}
        onOpen={onOpenView}
        onClose={onCloseView}
        title='Ver báscula'
        size='xl'
      >
        <FormViewWeighin dataToShow={dataToshow} onCloseView={onCloseView} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenConfig}
        onOpen={onOpenConfig}
        onClose={onCloseConfig}
        title='Configuración'
        size='xl'
        scrollBehavior={'outside'}
      >
        {selectpesaje && (
          <ListConfigBascula
            selectpesaje={selectpesaje}
            setselectpesaje={setselectpesaje}
          />
        )}
        <ConfigBascula
          onCloseConfig={onCloseConfig}
          selectpesaje={selectpesaje}
          setselectpesaje={setselectpesaje}
          onOpenList={onOpenList}
        />
      </ModalGeneric>

      <Box display={'flex'} justifyContent='right' w='100%' m='0.5rem 0.5rem'>
        <Box
          p={2}
          borderRadius={5}
          shadow={
            ' rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
          }
        >
          <HeaderWeighinhMachine
            onOpenAdd={onOpenAdd}
            onOpenConfig={onOpenConfig}
          />
        </Box>
      </Box>
      <HeaderSearch>
        <CardWeighingContainer>
          {dataBasculas.map((item, index) => (
            <>
              <CardWeighingNew
                data={item}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleView={handleView}
              />

              {/*  <CardWeighing key={index}>
                <DivIconCardWeighing
                  bg={
                    item.status === 1
                      ? "#03c39a"
                      : item.status === 0
                      ? "#BA1F33"
                      : "#03c39a"
                  }
                >
                  <IconCardWeighing color="#fff" as={FaWeight} />
                </DivIconCardWeighing>

                <DivContentCard>
                  <CardHeader>
                    <h1 id="title">{item?.name}</h1>
                     <h1 id="date">{item.fecha}</h1>
                  </CardHeader>

                  <CardContent>
                    <h1 id="cardContent">
                      Dirección: {item?.direction ? item?.direction : "N/A"}
                    </h1>
                    <h1 id="cardContent">
                      Estado: {item?.status === 1 ? "Activo" : "Inactivo"}
                    </h1>
                  </CardContent>
                  <CardContent>
                    <h1 id="cardContent">
                      Tasa de baudios:{" "}
                      {item?.baud_rate ? item?.baud_rate : "N/A"}
                    </h1>
                    <h1 id="cardContent">
                      Bits de datos: {item?.data_bits ? item?.data_bits : "N/A"}
                    </h1>
                  </CardContent>
                  <CardContent>
                    <h1 id="cardContent">
                      Paridad: {item?.parity ? item?.parity : "N/A"}
                    </h1>
                    <h1 id="cardContent">
                      Bits de parada:{" "}
                      {item?.stop_bits ? item?.stop_bits : "N/A"}
                    </h1>
                  </CardContent>

                  <CardFooter>
                    <h1 id="cardFooter" onClick={() => handleDelete(item.id)}>
                      Eliminar
                    </h1>
                    <h1 id="cardFooter" onClick={() => handleEdit(item)}>
                      Actualizar
                    </h1>
                    <h1 id="cardFooter" onClick={() => handleView(item)}>
                      Ver
                    </h1>
                  </CardFooter>
                </DivContentCard>
              </CardWeighing> */}
            </>
          ))}
        </CardWeighingContainer>
      </HeaderSearch>
    </>
  )
}
