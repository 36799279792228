/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { useModal } from "hooks/useModal";
import { GetLocalStorage, DeleteLocalstore } from "hooks/useLocalStorage";
import { useDisclosure } from "@chakra-ui/react";
import {
  __CopyForms,
  __DeleteConfig,
  __EditCreateForm,
  __EditCreateItem,
} from "request/Forms/_CrudForm";
import {
  __AddItemPass,
  __ChangeStatus,
  __CreateCommentary,
  __DeleteAttachment,
  __getTypeProducts,
  __ImportItemsMassively,
} from "request/Pases/__CrudPass";
import { useDispatch, useSelector } from "react-redux";
import { changeForms } from "redux/actions/FormsActions";
import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { setDataStatusBalance } from "redux/actions/statusBalanceaction";
import { __Post, __PostForm } from "request/Petitions/__Post";
import { __thirdPartiesNotPaginate } from "request/thirdparties/__GetThirdParties";
import { AuthContext } from "./AuthContext";
import {
  __OperationListExit,
  __OperationListId,
} from "request/configuration/__Operations";
import { __TransportMethodNotPaginate } from "request/configuration/__TransportMethod";
import { __SelectPaises } from "request/configuration/_Country";
import { __Packaging_All } from "request/configuration/__Packaging";
import { __Acuerdos } from "request/configuration/__ASubheading";
import {
  __ProductsReferenceUserAll,
  __ProductsUserAll,
} from "request/configuration/__ProductProcess";
import { __DepartmentsAll } from "request/configuration/__Cities";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { __GetDataId, __GetDataNoPage } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { handleLogout } from "components/Logout/FuncionLogout";
import { useQueryClient } from "@tanstack/react-query";
import { isObject } from "utils/type-check-utils";
import GlobalContext from "./GlobalContext";

const { createContext, useState, useContext } = require("react");

const FormCrudCRUDContext = createContext();

export const FormCrudCRUDProvider = ({ children }) => {
  // const [isOpenCarga, onOpenCarga, onCloseCarga] = useModal();
  const queryClient = useQueryClient();
  const [isOpenSearchItem, onOpenSearchItem, onCloseSearchItem] = useModal();
  const [requestObtenerItem, setRequestObtenerItem] = useState(null);
  const [editItemDispatch, seteditItemDispatch] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [dataItemDispachSelect, setDataItemDispachSelect] = useState(null);
  const [messageModalAsocciet, setmessageModalAsocciet] = useState(null);
  const [flagUpdateReviews, setflagUpdateReviews] = useState(false);
  const [exportPDFComponent, setExportPDFComponent] = useState(null);
  const [verifiedObservations, setVerifiedObservations] = useState(false);
  const [stateCurrent, setStateCurrent] = useState({});
  const [isOpenReturnReason, onOpenReturnReason, onCloseReturnReason] =
    useModal();
  const [isOpenAsoccietUnit, onOpenAsoccietUnit, onCloseAsoccietUnit] =
    useModal();
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal();
  const [isOpenComment, onOpenComment, onCloseComment] = useModal();
  const [isOpenCorrection, onOpenCorrection, onCloseCorrection] = useModal();
  const [isOpenRadicar, onOpenRadicar, onCloseRadicar] = useModal();
  const [isOpenRecuperar, onOpenRecuperar, onCloseRecuperar] = useModal();
  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal();
  const [isOpenDesechar, onOpenDesechar, onCloseDesechar] = useModal();
  const [isOpenCaducar, onOpenCaducar, onCloseCaducar] = useModal();
  const [isOpenAnular, onOpeAnular, onCloseAnular] = useModal();
  const [isOpenCopy, onOpenCopy, onCloseCopy] = useModal();
  const [isOpenShow, onOpenShow, onCloseShow] = useModal();
  const [openAlert] = AlertErrorAlert();
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToCaducar, setDataToCaducar] = useState(null);
  const [dataToAnular, setDataToAnular] = useState(null);
  const [isOpenCompany, onOpenCompany, onCloseCompany] = useModal();
  const [decimalUcContext, setDecimalUcContext] = useState(null);
  const [balanceCorrecedState, setbalanceCorrecedState] = useState(null);
  useModal();
  const [isOpenDesCaducar, onOpenDesCaducar, onCloseDesCaducar] = useModal();
  const [dataDescaducar, setDataDescaducar] = useState(null);
  const [updateThirdParties, setupdateThirdParties] = useState(false);
  const [dataFormCurrent, setDataFormCurrent] = useState(null);
  const [show, setShow] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const [dataToEditItems, setDataToEditItems] = useState(null);
  const [dataToCopyItems, setDataToCopyItems] = useState(null);
  const [dataToDesechar, setDataToDesechar] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [dataToCopy, setDataToCopy] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null);
  const [requestEditItem, setRequestEditItem] = useState(null);
  const [requestCreateItem, setRequestCreateItem] = useState(null);
  const [requestCreateThird, setRequestCreateThird] = useState(null);
  const [requestChangeStatusForm, setRequestChangeStatusForm] = useState(null);
  const [requestCreatePassNew, setRequestCreatePassNew] = useState(null);
  const [requestCorrectDocument, setRequestCorrectDocument] = useState(null);
  const [ReturnReason, setReturnReason] = useState(null);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false)

  // //
  const [opcional, setOpcional] = useState(null);
  const [opcionalCurrent, setOpcionalCurrent] = useState(null);
  const [typeEntry, setTypeEntry] = useState("DTA");
  const [typeEntryCurrent, setTypeEntryCurrent] = useState("DTA");

  // //
  // //
  const [requestDelete, setRequestDelete] = useState(null);
  const [requestCreate, setRequestCreate] = useState(null);
  const [requestCopy, setRequestCopy] = useState(null); //
  //
  const [formEdit, setFormEdit] = useState(null);
  const [formShow, setFormShow] = useState(null);
  const [formLicensias, setFormLicensias] = useState(null);
  const [dataCorrection, setDataCorrection] = useState(null);
  const [dataRecuperar, setDataRecuperar] = useState(null);
  const [dataRadicar, setDataRadicar] = useState(null);
  const [dataAsocciet, setdataAsocciet] = useState(null);
  const [formDocument, setDocument] = useState(null);
  const [update, setUpdate] = useState(true);
  const [newCommentary, setNewCommentary] = useState(true);
  const [dataCostos, setDataCostos] = useState(null);
  const [ReviewsData, setReviewsData] = useState([]);

  const [selectExport, setselectExport] = useState(null);
  const [StateCurrentExport, setStateCurrentExport] = useState(null);
  const [selectOperation, setselectOperation] = useState(null);
  const [selectEmbarcacion, setselectEmbarcacion] = useState(null);
  const [dataSelectOperation, setDataSelectOperation] = useState(null);
  const [dataSelectExport, setDataSelectExport] = useState(null);

  // Data select operacion
  const [dataOperation, setDataOperation] = useState([]);
  const [selectItemForm, setSelectItemForm] = useState("");
  const [selectIdOp, setSelectIdOp] = useState("");
  const [nameOperation, setNameOperation] = useState("");

  const [DocumentOperation, setDocumentOperation] = useState([]);
  // Data Select Departamen
  const [dataDepartamentNew, setDataDepartamentNew] = useState([]);
  const [dataDepartament, setDataDepartament] = useState([]);
  const [valueDepartament, setValueDepartament] = useState("");
  const [StateCountry, setStateCountry] = useState(null);
  const [StateNit, setStateNit] = useState(null);

  // data de administracion duana
  const [adminCustom, setAdminCustom] = useState(null);

  const [departament, setDepartament] = useState("");
  //  Data Selet User Qualified from user to user
  const [userQualified, setUserQuialified] = useState(null);
  const [userQualifiedNew, setUserQuialifiedNew] = useState(null);
  const [valueQualifiedForm, setValueQuialifiedForm] = useState("");
  const [nameQualifiedForm, setNameQualifiedForm] = useState("");
  //  Data Selet pais de origen
  const [dataCountry, setDataCountry] = useState([]);
  const [dataProcedencia, setDataProcedencia] = useState(null);
  const [dataCountry120, setDataCountry120] = useState(null);
  const [info, setInfo] = useState(null);
  //  Data Selet pais de procedencia
  const [dataCountryOrigin, setDataCountryOrigin] = useState(null);
  //  Data Selet pais de compra
  const [dataCountryBuy, setDataCountryBuy] = useState({});
  //  Data Selet Pais
  const [unidad, setUnidad] = useState("");
  const [placeU, setPlaceU] = useState("");
  //  Data Selet Metodo de Transporte
  const [dataTransportMethod, setDataTransportMethod] = useState([]);
  const [dataTransportMethodNew, setDataTransportMethodNew] = useState(null);
  const [valueMethod, setValueMethod] = useState("");
  const [etiqueta, setEtiqueta] = useState("");
  //  Data Selet User Qualified Data Company
  const [valueQualified, setValueQuialified] = useState(null);
  const [nameQualified, setNameQualified] = useState("");
  // Radio Button ascedente O descendente
  const [selectAD, setSelectAD] = useState("Ascendente");
  // Radio Button unico O Fraccionado
  const [selectUF, setSelectUF] = useState("Único");
  // Radio Button Nacional O Extragero
  const [selectNE, setSelectNE] = useState(null);
  // Radio Button moneda USD O COP
  const [selectCurrency, setSelectCurrency] = useState("USD");
  // Radio Button Monedas
  // Data Select Third
  const [dataThird, setDataThird] = useState([]);
  const [dataThirdDefault, setDataThirdDefault] = useState([]);
  const [valueThird, setValueThird] = useState("");
  const [nameThird, setNameThird] = useState("");
  //  Select Bandera
  const [valueFlag, setValueFlag] = useState(null);
  // Checks
  const [portM, setPortM] = useState(false);
  const [portMCorrect, setPortMCorrect] = useState(false);
  const [inspeccion, setInspeccion] = useState(false);
  const [inspeccionCorrect, setInspeccionCorrect] = useState(false);
  // Select acuerdos comerciales
  const [dataAgreements, setDataAgreements] = useState([]);
  const [dataAgreementsNew, setDataAgreementsNew] = useState([]);
  const [acuerdos, setAcuerdos] = useState(null);
  // Select Embalaje
  const [dataPackagings, setDataPackagings] = useState([]);
  const [dataPackagingsNew, setDataPackagingsNew] = useState([]);
  const [packagings, setPackagings] = useState("");
  // Select Subpartida
  const [dataSubheading, setDataSuheading] = useState([]);
  const [valueSubheading, setValueSubheading] = useState("");
  const [nameSubheading, setNameSubheading] = useState("");
  // select Productos
  const [allProducts, setAllProducts] = useState([]);

  const [dataProducts, setDataProducts] = useState([]);
  const [nameProducts, setNameProducts] = useState("");
  const [valueProducts, setValueProducts] = useState(null);
  // TRM
  const [dataTrm, setDataTrm] = useState([]);
  // Estado para que pueda aparecer la tabla
  const [addItem, setAdditem] = useState(false);
  // Select type products
  const [stateCollapse, setstateCollapse] = useState(true);
  const [showItemsView, setshowItemsView] = useState(false);
  const [showCorrectBalance, setshowCorrectBalance] = useState(false);

  const [refunds, setRefunds] = useState(null);
  const [dataTypeProduct, setDataTypeProduct] = useState([]);
  const [selectDataTypeProduct, setSelectDataTypeProduct] = useState(null);
  // state de administracion de aduanas
  const [customsadministration, setCustomsadministration] = useState(null);
  const dispatch = useDispatch();
  const dispatchRedux = useDispatch();
  const dispacthRedux = useDispatch();

  const { authUser, dispatchContext = dispatch } = useContext(AuthContext);
  const [loadingItems, setLoadingItems] = useState(false);
  const [changeExportExcel, setChangeExportExcel] = useState(false);
  const [searchAdvancedPosition, setSearchAdvancedPosition] =
    useState("Document");
  const [userImage, setUserImage] = useState("");

  /* Current item length */
  const [currentItemLength, setCurrentItemLength] = useState(0);
  const [letNumDepartureAuthorization, setLetNumDepartureAuthorization] =
    useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const {
    movement = "",
    ExitNumber = "",
    FormEntry = "",
    ShowEntry = null,
    ShowOutput = null,
  } = queryString.parse(location.search);
  // console.log('queryString.parse(location.search)', queryString.parse(location.search))
  const { token, freeZone_id, port } = useSelector(
    (state) => state.auth.dataUser
  );
  const { balance } = useSelector((state) => state.status);
  const { reviewsData } = useContext(GlobalContext);

  const dataType = ["Planilla de envío", "DTA", "Cabotaje"];
  const dataDUTA = ["DUTA"];
  const dataNational = ["Nacional", "Extranjera"];
  const addresDestine = [206, 208, 218, 219, 408, 409, 417, 425];

  const arrayPruebaOutput = [
    "product_quantity",
    "transportMethod_id",
    "packaging_id",
    "countryOrigin_id",
    "flag_id",
    "countryPurchase_id",
    "product_id",
  ];

  const [changeQualified, setChangeQualified] = useState(null);
  const [onFmm, setOnFmm] = useState(false);

  /* Valor del dolar */
  const [valorDolarActual, setValorDolarActual] = useState(0);

  /* Aquí se almacenarán los items de autorización de salida seleccionados o si se seleccionaron todos */
  const [isOpenSelectAuthorizationItems, onOpenSelectAuthorizationItems, onCloseSelectAuthorizationItems] = useModal();
  const [itemsSelected, setItemsSelected] = useState([]);
  const [isSelectedAllItems, setIsSelectedAllItems] = useState(false);

  /**
   * Crea la data que le pasamos por paramentros
   * guarda en el endpoint que le pasemos mediante el estado de requestCreate
   * Ejecuta la funcion __EditCreateForm. Esta funcion recibe el endpoint, el token y la data a crear
   */

  const createData = async (data) => {
    try {
      const res = await __EditCreateForm(requestCreate, token, data);
      const resultado = res?.data?.status;

      if (resultado?.code === 200) {
        setUpdate(!update);
        const dataBalanceFmm = resultado?.data.balance;
        localStorage.setItem("ValueBalance", JSON.stringify(dataBalanceFmm));
        dispatchRedux(setDataStatusBalance(!balance));
        openAlert(`Registro actualizado Exitosamente`, "success");
        if (pathname === "/FormEntry") {
          const dataFmm = resultado?.data.entry_form;
          setDataToShow(dataFmm);
          dispatch(changeForms(dataFmm?.id));
          setDataToEdit(dataFmm);
          navigate(`/FormEntry?FormEntryNumber=${dataFmm?.id}`);
        } else if (pathname === "/FormExit") {
          const dataFmm = resultado?.data.output_form;
          setDataToShow(dataFmm);
          dispatch(changeForms(dataFmm?.id));
          setDataToEdit(dataFmm);
          navigate(`/FormExit?FormExitNumber=${dataFmm?.id}`);
        }
      } else {
        if (resultado?.message) {
          openAlert(resultado?.message, "error");
        } else {
          Object.values(resultado?.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editDataForm = async (
    info,
    rediret = true,
    successCallback = () => { },
    cancelUpdate = false
  ) => {
    try {
      const res = await __EditCreateForm(requestEdit, token, info);
      if (res.data.status.code === 200) {
        const { id } = res.data.status.data;
        setDataToEdit(null);
        openAlert(`Registro actualizado Exitosamente`, "success");
        if (!cancelUpdate) setUpdate(!update);
        successCallback();
        if (!rediret) return;
        if (pathname === "/FormExit" || pathname === "/FormShowExit") {
          return navigate(`/FormShowExit?ExitNumber=${id}`);
        }
        return navigate(`/FormShow?FormEntry=${id}`);
      }
      setDataToEdit(!dataToEdit);
      openAlert(res?.data?.status?.message, "error");
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const copyData = async (data) => {
    try {
      const res = await __CopyForms(requestCopy, token, dataToCopy.id);
      if (res?.data?.status?.code === 200) {
        setDataToEdit(res?.data?.status?.data);
        onCloseCopy();
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
        if (movement === "Salida" || ExitNumber !== "") {
          navigate(`/FormExit?FormExitNumber=${res?.data?.status?.data?.id}`);
        } else {
          navigate(`/FormShow?FormEntry=${res?.data?.status?.data?.id}`);
        }
      } else {
        if (res?.data?.status?.message) {
          openAlert(res?.data?.status?.message, "error");
        } else {
          Object.values(res?.data?.status?.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const CreateItems = async (
    data,
    cancelar,
    callBack = () => { },
    callBack2 = () => { }
  ) => {
    try {
      const res = await __EditCreateItem(requestCreateItem, token, data);
      if (res.data.status.code === 200) {
        callBack2();
        openAlert(`Registro actualizado Exitosamente`, "success");
        cancelar();
        setUpdate(!update);
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      callBack();
    }
  };

  const ObtenerCosto = async (endpoint, data) => {
    try {
      const res = await __PostForm(endpoint, token, data);
      const respuestaDes = res?.data?.status;
      if (respuestaDes?.code === 200) {
        setDataCostos(respuestaDes?.data);
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const EditItems = async (
    data,
    cancelar = () => { },
    callBack = () => { },
    callBack2 = () => { }
  ) => {
    try {
      const res = await __EditCreateItem(requestEditItem, token, data);
      if (res.data.status.code === 200) {
        callBack2();
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
        cancelar();
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      callBack();
    }
  };

  const deleteAttachment = async (
    endpoint,
    id,
    data,
    set,
    newData,
    callBack = () => { }
  ) => {
    try {
      const res = await __DeleteAttachment(endpoint, token, data, id);
      if (res.data.status.code === 200) {
        openAlert(res?.data?.status?.message, "success");
        if (set) set(newData);
      } else {
        openAlert(res?.data?.status?.message, "error");
      }

      callBack();
    } catch (error) {
      console.log(error);
    }
  };

  const reviewsSave = async (data, apiUrl, callBack = () => { }) => {
    try {
      const resp = await __Post(apiUrl, token, data);

      if (resp.data.status.code === 200) {
        openAlert(resp?.data?.status?.message, "success");
      } else {
        openAlert(resp?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      callBack();
    }
  };

  const changeStatus = async (data, queryKey = "tableData") => {
    const formStatusMapping = {
      "/CheckFormEntry": "status_entryForm",
      "/CheckCorrectEntry": "status_entryFormCorrected",
      "/CheckCorrectOutput": "status_outputFormCorrected",
      "/CheckForm": "status_outputForm",
    };

    const apiUrls = {
      "/CheckFormEntry": environment.REVIEW_FOR_ENTRY,
      "/CheckCorrectEntry": environment.POST_ENTRY_FORM_CORRECTED,
      "/CheckCorrectOutput": environment.POST_OUTPUT_FORM_CORRECTED,
      "/CheckForm": environment.REVIEW_FOR_EXIT,
    };

    const allowedRoutes = [
      "/CheckFormEntry",
      "/CheckCorrectEntry",
      "/CheckCorrectOutput",
      "/CheckForm",
    ];

    // const isCurrentRouteAllowed = allowedRoutes.includes(location.pathname);
    // apiUrls[pathname]

    return changeFromStatus(data, () => DeleteLocalstore(info.id), queryKey);
  };

  const changeFromStatus = async (data, callBack = () => { }, key = "tableData") => {
    const idCurrent = data?.id
    const Mode_Robot = GetLocalStorage("Mode_Robot");

    // Este problema debería ser resuelto en el backend, ya que es la solución ideal. Sin embargo, debido a la sobrecarga de trabajo en el equipo de backend,
    // no pueden abordarlo en este momento. Como solución temporal, se ha implementado este ajuste desde el frontend. 
    // Una vez que el backend pueda encargarse del problema, solo será necesario eliminar las propiedades 'free_pass' e 'inspection' 
    // de este código, ya que el backend se encargará de asignarlas automáticamente y no será necesario enviarlas constantemente desde el frontend.


     // Eliminar propiedades no deseadas del objeto data
    const { free_pass :_ , ...cleanedData } = data;

  // Agregar nuevas propiedades si es necesario
    const enhancedData = {
     ...cleanedData,
      //timestamp: new Date().toISOString(),
      free_pass: reviewsData?.free_pass,
      inspection: reviewsData?.inspection,
      // Agrega aquí más propiedades según sea necesario
   };
   setIsLoadingChangeStatus(true)
    try {
      const res = await __ChangeStatus(requestChangeStatusForm, token, enhancedData);
      const {
        status_entryForm,
        status_outputForm,
        status_entryPass,
        status_dispatchPass,
        status_entryFormCorrected,
      } = data;

      let estadoNuevo;
      if (status_entryForm === "BORRADOR" || status_outputForm === "BORRADOR") {
        estadoNuevo = "recuperado";
      } else if (
        status_entryForm === "PRESENTADO" ||
        status_outputForm === "PRESENTADO"
      ) {
        estadoNuevo = "presentado";
      } else if (
        status_entryForm === "APROBADO" ||
        status_outputForm === "APROBADO"
      ) {
        estadoNuevo = "aprobado";
      } else if (
        status_entryForm === "AUTORIZADO" ||
        status_outputForm === "AUTORIZADO"
      ) {
        estadoNuevo = "autorizado";
      } else if (
        status_entryForm === "CORREGIDO" ||
        status_outputForm === "CORREGIDO"
      ) {
        estadoNuevo = "corregido";
      } else if (
        status_entryForm === "CADUCADO" ||
        status_outputForm === "CADUCADO"
      ) {
        estadoNuevo = "caducado";
      } else if (
        status_entryForm === "ANULADO" ||
        status_outputForm === "ANULADO"
      ) {
        estadoNuevo = "anulado";
      } else if (
        status_entryPass === "DEVUELTO" ||
        status_dispatchPass === "DEVUELTO" ||
        status_entryForm === "DEVUELTO" ||
        status_outputForm === "DEVUELTO" ||
        status_entryFormCorrected === "DEVUELTO"
      ) {
        estadoNuevo = "devuelto";
      } else if (
        status_entryForm === "REVISADO" ||
        status_dispatchPass === "REVISADO" ||
        status_outputForm === "REVISADO"
      ) {
        estadoNuevo = "revisar";
      } else {
        estadoNuevo = "desechado";
      }

      if (res.data.status.code === 200) {
        onCloseDesechar();
        onCloseReturnReason();
        onCloseDesCaducar();
        setDataDescaducar(null);
        const { entry_form, output_form } = res.data.status.data;

        if (estadoNuevo !== "revisar") {
          openAlert(`El formulario fue ${estadoNuevo} Exitosamente`, "success");
        } else {
          openAlert(`El formulario se encuentra en revisión`, "success");
        }

        setUpdate(!update);
        onCloseRecuperar();
        queryClient?.invalidateQueries({ queryKey: [key] });

        if (
          movement === "Ingreso" ||
          movement === "Salida" ||
          FormEntry ||
          ExitNumber
        ) {
          if (entry_form?.status_entryForm === "APROBADO") {
            if (Mode_Robot) {
              navigate(`/Robot-mode`);
            } else {
              navigate(
                `/FormShow?FormEntry=${idCurrent || res.data.status.data.entry_form?.id}`
              );
            }
          } else if (output_form?.status_outputForm === "APROBADO") {
            if (Mode_Robot) {
              navigate(`/Robot-mode`);
            } else {
              navigate(
                `/FormShowExit?ExitNumber=${idCurrent || res?.data?.status?.data?.output_form?.id}`
              );
            }
          } else {
            setUpdate(!update);
          }
        } else {
          if (entry_form) {
            if (
              entry_form?.status_entryForm === "ELIMINADO" ||
              entry_form?.status_entryForm === "PRESENTADO" ||
              entry_form?.status_entryForm === "ANULADO" ||
              entry_form?.status_entryForm === "CADUCADO" ||
              entry_form?.status_entryForm === "DEVUELTO"
            ) {
              if (Mode_Robot) {
                navigate(`/Robot-mode`);
              } else {
                navigate(`/Form-tables-entry?movement=Ingreso`);
              }
            } else if (entry_form.status_entryForm === "APROBADO") {
              if (Mode_Robot) {
                navigate(`/Robot-mode`);
              } else {
                navigate(
                  `/FormShow?FormEntry=${idCurrent || res?.data?.status?.data?.entry_form?.id}`
                );
              }//idCurrent
            }
          } else if (output_form) {
            if (
              output_form?.status_outputForm === "ELIMINADO" ||
              output_form?.status_outputForm === "PRESENTADO" ||
              output_form?.status_outputForm === "CADUCADO" ||
              output_form?.status_outputForm === "ANULADO" ||
              output_form?.status_outputForm === "DEVUELTO"
            ) {
              if (Mode_Robot) {
                navigate(`/Robot-mode`);
              } else {
                navigate(`/Form-tables-output?movement=Salida`);
              }
            } else if (output_form?.status_outputForm === "APROBADO") {
              if (Mode_Robot) {
                navigate(`/Robot-mode`);
              } else {
                navigate(
                  `/FormShowExit?ExitNumber=${idCurrent || res.data.status.data.output_form.id}`
                );
              }
            }
          }
        }
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingChangeStatus(false)
      callBack();
    }
  };

  const exportColumnBy = async (data, onCloseExport) => {
    try {
      const res = await __Post(environment.EXPORT_PRODUCT, token, data);
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        /* let url = res?.data;
        window.location.assign(url); */
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatusCorregido = async (data, callBack = () => { }) => {
    const Mode_Robot = GetLocalStorage("Mode_Robot");

    const { status_entryFormCorrected, status_outputFormCorrected, id } = data;

    try {
      const res = await __ChangeStatus(requestChangeStatusForm, token, data);

      if (res.data.status.code === 200) {
        if (ShowEntry || ShowOutput) {
          if (status_entryFormCorrected === "DEVUELTO") {
            navigate(`/FormCorrection?ShowEntry=${id}`);
          }

          if (status_outputFormCorrected === "DEVUELTO") {
            navigate(`/FormCorrectionOutput?ShowOutput=${id}`);
          }
        }

        onCloseDesechar();
        onCloseReturnReason();
        openAlert(
          `El formulario fue ${status_outputFormCorrected?.toLowerCase() ||
          status_entryFormCorrected.toLowerCase()
          } Exitosamente`,
          "success"
        );

        onCloseReturnReason();
        onCloseRadicar();
        onCloseRecuperar();
        callBack();
        const { data } = res.data.status;

        if (
          movement === "Corregido de ingreso" ||
          movement === "Corregido de salida"
        ) {
          setUpdate(!update);
        } else {
          if (data.status_entryFormCorrected) {
            console.log(data);
            if (
              data.status_entryFormCorrected === "ELIMINADO" ||
              data.status_entryFormCorrected === "ANULADO" ||
              data.status_entryFormCorrected === "CADUCADO" ||
              data.status_entryFormCorrected === "DEVUELTO"
            ) {
              if (Mode_Robot) {
                navigate(`/Robot-mode`);
              } else {
                navigate(`/Correct-Form?movement=Corregido de ingreso`);
              }
            } else if (data.status_entryFormCorrected === "AUTORIZADO") {
              if (Mode_Robot) {
                navigate(`/Robot-mode`);
              } else {
                navigate(`/FormCorrection?ShowEntry=${data.id}`);
              }
            } else {
              setUpdate(!update);
            }
          } else if (data.status_outputFormCorrected) {
            if (
              data.status_outputFormCorrected === "ELIMINADO" ||
              data.status_outputFormCorrected === "CADUCADO" ||
              data.status_outputFormCorrected === "ANULADO" ||
              data.status_outputFormCorrected === "DEVUELTO"
            ) {
              if (Mode_Robot) {
                navigate(`/Robot-mode`);
              } else {
                navigate(`/Correct-Form-output?movement=Corregido de salida`);
              }
            } else if (data.status_outputFormCorrected === "AUTORIZADO") {
              if (Mode_Robot) {
                navigate(`/Robot-mode`);
              } else {
                navigate(`/FormCorrectionOutput?ShowOutput=${data.id}`);
              }
            } else {
              setUpdate(!update);
            }
          }
        }
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async (api, callBack = () => { }, isUpdate = true) => {
    try {
      const res = await __DeleteConfig(requestDelete || api, token, dataToDelete.id);
      if (res.data.status.code === 200) {
        setDataToDelete(null);
        onCloseDelete();
        callBack()
        openAlert(`Registro eliminado Exitosamente`, "success");
        if (isUpdate) setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteItemsCorrect = async () => {
    // console.log(dataToDelete);
    try {
      const res = await __DeleteConfig(
        requestDelete,
        token,
        `${dataToDelete?.id}${dataToDelete?.item_original_id
          ? `/${dataToDelete?.item_original_id}`
          : ""
        }`
      );
      if (res.data.status.code === 200) {
        setDataToDelete(null);
        onCloseDelete();
        openAlert(`Registro eliminado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showData = () => {
    console.log(dataToShow);
  };

  const onCloseModal = () => {
    if (isOpenEdit) {
      onCloseEdit();
      setDataToEdit(null);
      return;
    }
    if (isOpenDelete) {
      onCloseDelete();
      return;
    }

    if (isOpenCopy) {
      onCloseCopy();
      return;
    }
    if (isOpenShow) {
      onCloseShow();
      return;
    }
    if (isOpenCaducar) {
      onCloseCaducar();
      setDataToCaducar(null);
    }
    if (isOpenAnular) {
      onCloseAnular();
      setDataToAnular(null);
    }
  };
  const idFuncionaio = useSelector((state) => state.auth.dataUser.id);

  const createCommentary = async (endpoint, data) => {
    try {
      const res = await __CreateCommentary(endpoint, token, data);
      if (res.data.status.code !== 200) {
        openAlert(`${res.data.status.message}`, "error");
        if (res.data.status.message === "El token expiró.") {
          handleLogout(
            token,
            dispatchContext,
            dispacthRedux,
            navigate,
            idFuncionaio
          );
        }
      } else {
        setNewCommentary(!newCommentary);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ImportItems = async (endpoint, data, onClose) => {
    try {
      const res = await __ImportItemsMassively(endpoint, token, data);
      const respuestaAttachment = res.data.status;
      if (respuestaAttachment.code === 200) {
        openAlert(`${respuestaAttachment.data}`, "success");
        setUpdate(!update);
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addItemPass = async (data) => {
    try {
      const res = await __AddItemPass(requestCreatePassNew, token, data);
      if (res.data.status.code === 200) {
        const dataBalanceFmm = res.data.status?.data.balance;
        if (dataBalanceFmm) {
          localStorage.setItem("ValueBalance", JSON.stringify(dataBalanceFmm));
        }

        const numberFormIn = res.data.status.data.entry_pass;
        const numberFormExit = res.data.status.data.dispatch_pass;

        openAlert(res.data.status.message, "success");
        if (numberFormIn) {
          navigate(
            `/formInt?qualifiedUser=${numberFormIn.name_qualified}&numberForm=${numberFormIn.passForm_number
            }&id=${numberFormIn.id}&status=${numberFormIn.status_entryPass
            }&typeO=${""}`
          );
        } else if (numberFormExit) {
          navigate(
            `/formInt?qualifiedUser=${numberFormExit.name_qualified
            }&numberForm=${numberFormExit.passForm_number}&id=${numberFormExit.id
            }&status=${numberFormExit.status_dispatchPass}&typeO=${"Dispatch"}`
          );
        }

        onCloseAsoccietUnit();
        setUpdate(!update);
      } else {
        const result = res.data.status.message;
        const dato = typeof result;

        if (dato === "object") {
          setmessageModalAsocciet(res.data.status.message.message);
          const info = {
            ...data,
            confirm: res.data.status.message.confirm,
          };
          setdataAsocciet(info);
          onOpenAsoccietUnit();
        } else {
          openAlert(res.data.status.message, "error");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const newDispacthwithItem = async (data) => {
    try {
      const resultado = await __AddItemPass(requestCreatePassNew, token, data);

      if (resultado.data.status.code === 200) {
        const dataBalanceFmm = resultado.data.status?.data.balance;
        if (dataBalanceFmm) {
          localStorage.setItem("ValueBalance", JSON.stringify(dataBalanceFmm));
        }
        const numberFormIn = resultado.data.status.data.entry_pass;
        const numberFormExit = resultado.data.status.data.output_form;

        openAlert(`Pase creado exitosamente`, "success");

        if (numberFormIn) {
          navigate(
            `/formInt?qualifiedUser=${numberFormIn.name_qualified}&numberForm=${numberFormIn.passForm_number
            }&id=${numberFormIn.id}&status=${numberFormIn.status_entryPass
            }&typeO=${""}`
          );
        } else if (numberFormExit) {
          navigate(
            `/formInt?qualifiedUser=${numberFormIn.name_qualified}&numberForm=${numberFormIn.passForm_number
            }&id=${numberFormIn.id}&status=${numberFormIn.status_dispatchPass
            }&typeO=${"Dispatch"}`
          );
        }
      } else {
        openAlert(`${resultado.data.status.message}`, "error");
      }
    } catch (error) {
      console.log(error);
      console.log("Error");
    }
  };

  const getThirdParties = async (id) => {
    try {
      const res = await __thirdPartiesNotPaginate(
        token,
        authUser.is_qualified_user !== 1 ? id : ""
      );
      const data = res?.data?.status.data;

      if (pathname === "/FormExit" || pathname === "/FormCorrectionOutput") {
        setDataThirdDefault(data);
        const infoThird = data.map((item) => ({
          value: item?.id,
          label: `${item.identification_num !== null ? item?.identification_num : " "
            } ${item?.business_name} `,
          ...item,
        }));
        if (data !== undefined) {
          setDataThird(infoThird);
        }
      } else {
        setDataThird(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOperationNotPaginate = async (id) => {
    try {
      const res = await __OperationListId(token, id ?? "");
      const data = res?.data?.status?.data;
      setDataOperation(data);
    } catch (error) {
      throw error;
    }
  };

  const getOperationOutput = async (id) => {
    try {
      const res = await __OperationListExit(
        token,
        id && id
        // authUser.is_qualified_user !== 1 && valueQualified
      );
      const data = res?.data?.status?.data;
      setDataOperation(data);
    } catch (error) {
      throw error;
    }
  };

  const getMethodTransport = async () => {
    try {
      const res = await __TransportMethodNotPaginate(token);
      setDataTransportMethod(res?.data?.status?.data);
    } catch (error) { }
  };

  const getCountry = async (codeOperations) => {
    try {
      const res = await __SelectPaises(token);
      const indoCountry = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_country + " " + item?.name_country,
        origin_country: item?.origin_country,
        code: item?.code_country,
      }));
      setDataCountry(indoCountry);

      if (codeOperations && codeOperations === 120) {
        const serachIZF = indoCountry.filter(({ code }) => code === "911");
        return setDataCountry120(serachIZF);
      } else if (
        codeOperations &&
        codeOperations >= 300 &&
        codeOperations <= 399
      ) {
        const serachIZF = indoCountry.filter(({ code }) => code === "169");
        return setDataProcedencia(serachIZF);
      } else if (
        codeOperations &&
        codeOperations >= 500 &&
        codeOperations <= 599
      ) {
        const serachIZF = indoCountry.filter(
          ({ origin_country }) => origin_country === "IZ"
        );
        return setDataProcedencia(serachIZF);
      } else {
        setDataProcedencia(null);
        setDataCountry120(null);
      }
    } catch (error) { }
  };

  const getPackaging = async () => {
    try {
      const res = await __Packaging_All(token);
      setDataPackagings(res?.data?.status?.data);
    } catch (error) { }
  };

  const getAcuerdosC = async () => {
    try {
      const res = await __Acuerdos(token);
      setDataAgreements(res?.data?.status?.data);
    } catch (error) { }
  };

  const getCodeProduct = async (id, body) => {
    const formdata = new FormData();
    formdata.append("search", body);

    try {
      const res = await __ProductsReferenceUserAll(token, id, formdata);
      const infoProduct = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_product,
      }));
      setDataProducts(infoProduct);
      setAllProducts(res?.data?.status?.data);
    } catch (error) { }
  };

  // crear la correcion
  const CorrectDocument = async (endpoint, info, funcionCallback) => {
    try {
      const res = await __Post(endpoint, token, info);
      const destrucData = res?.data?.status;
      const { code, message, data } = destrucData;
      if (code === 200) {
        if (movement === "Ingreso" || pathname === "/FormShow") {
          navigate(`/FormCorrection?FormEntry=${data.id}`);
        } else {
          navigate(
            `/FormCorrectionOutput?FormOutput=${data.output_form_corrected.id}`
          );
        }
        if (funcionCallback) {
          funcionCallback();
        }
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCorrectDocument = async (
    endpoint,
    info,
    handleCancelCabecera,
    redireccionar
  ) => {
    try {
      const res = await __Post(endpoint, token, info);
      const destrucData = res?.data?.status;
      const { code, message, data } = destrucData;
      if (code === 200) {
        if (handleCancelCabecera) {
          handleCancelCabecera();
        }
        navigate(`${redireccionar}=${data.id}`);
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      return openAlert(error, "error");
    }
  };

  const ShowPayment = async (endpoint, idInfo) => {
    try {
      const res = await __GetDataId(endpoint, token, idInfo);
      const destrucData = res?.data?.status;
      const { code, data } = destrucData;
      if (code === 200) {
        return data;
      } else {
        return { code, res };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartament = async () => {
    try {
      const res = await __DepartmentsAll(token);
      const data = res?.data?.status.data;
      setDataDepartament(data);
    } catch (error) { }
  };

  const getAdminCustom = async () => {
    try {
      const res = await __GetDataNoPage(
        environment.CUSTOMS_GET_NOT_PAGE,
        token
      );
      const data = res?.data?.status.data;
      const dataCustom = data.map((item) => ({
        value: item?.id,
        label: item?.code_custom + " " + item?.name_custom,
      }));

      setAdminCustom(dataCustom);
    } catch (error) { }
  };

  const getUserQualified = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const data = res?.data?.status?.data;
      setUserQuialified(data);
    } catch (error) { }
  };

  const typeProduct = async () => {
    try {
      const res = await __getTypeProducts(token);
      const data = res?.data?.status?.data;
      const typeProduct = data.map((item) => ({
        value: item?.id,
        label: item?.name_typeProduct,
      }));
      setDataTypeProduct(typeProduct);
      // setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  const CorrectItem = async (
    endpoint,
    info,
    close,
    messages,
    setquantityError
  ) => {
    try {
      const res = await __Post(endpoint, token, info);
      const destrucData = res?.data?.status;
      // const { code, message } = destrucData;
      if (res?.data?.code === 200 || destrucData?.code === 200) {
        setUpdate(!update);
        openAlert(`${messages || "Item actualizado con exito"}`, "success");
        if (close) {
          close();
        }
      } else if (res?.data?.code !== 200 || destrucData?.code !== 200) {
        openAlert(
          destrucData?.message ? destrucData?.message : res?.data?.message,
          "error"
        );

        if (setquantityError) {
          setquantityError(res?.data?.data);
          openAlert(
            isObject(res?.data?.data) && res?.data?.data?.length > 0 && "Tiene cantidades con error",
            "error"
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getThirdParties =  async (id) => {
  //     try {
  //       const res = await __thirdPartiesNotPaginate(token, id && id);
  //       const data = res?.data?.status?.data;
  //       let infoThird = data.map((item) => ({
  //         value: item?.id,
  //         label: `${item.nit !== null ? item?.nit : " "} ${
  //           item?.business_name
  //         } `,
  //       }));
  //       if (data !== undefined) {
  //         setDataThird(infoThird);
  //       }
  //     } catch (error) {}
  //   }

  const handleCorrection = async (indoData, state) => {
    try {
      const res = await ShowPayment(
        movement === "Ingreso" || pathname === "/FormShow"
          ? environment.CORRECT_FMMI_SHOW_PAYMENT
          : environment.CORRECT_FMMO_SHOW_PAYMENT,
        indoData.id
      );

      if (res?.code === 400)
        return openAlert(`${res?.res?.data?.status?.message}`, "error");

      if (res?.status_form === "AUTORIZADO" || res?.code === 403) {
        const formData = new FormData();
        formData.append("qualifiedUser_id", indoData.qualifiedUser_id);
        ObtenerCosto(environment.TREASURY_UPDATE_RATE_GET, formData);
        setDataCorrection(indoData);
        console.log({ state });
        setshowCorrectBalance(state === 1);
        return onOpenCorrection();
      }

      const { id } = res;
      if (movement === "Ingreso" || pathname === "/FormShow") {
        return navigate(
          res?.status_form === "REVISADO"
            ? `/CheckCorrectEntry?ShowEntry=${id}`
            : `/FormCorrection?ShowEntry=${id}`
        );
      }

      if (movement === "Salida" || pathname === "/FormShowExit") {
        return navigate(
          res?.status_form === "REVISADO"
            ? `/CheckCorrectOutput?ShowOutput=${id}`
            : res.status_form === "PRESENTADO"
              ? `/FormCorrectionOutput?ShowOutput=${id}`
              : `/FormCorrectionOutput?FormOutput=${id}`
        );
      }

      return console.log({ res });
    } catch (error) {
      console.log(error);
    }
  };

  const iteradorStr = (str) => {
    const noComa = str.replaceAll(",", "");
    const noPunto = noComa.replaceAll(".", "");
    const value = 0;
    let found = true;
    for (let i = 0; i < noPunto.length; i++) {
      const converNum = Number(noPunto[i]);
      if (converNum !== value) {
        found = false;
        break;
      }
    }
    return found;
  };

  const getItemsPrev = async (
    endpoint,
    idfmmForItems,
    arrayPrueba,
    code,
    service,
    currentDataForm = null
  ) => {
    try {
      const res = await __GetDataId(endpoint, token, idfmmForItems);
      const data = res.data.status.data;
      const arrayIdItem = [];
      const objectField = {};
      const arrayOpeExce = [114, 222, 320, 429, 710, 811];

      if (service === 1) {
        return;
      }

      data.map((e) => {
        Object.entries(e).map(([key, valor]) => {
          const validatePort =
            key === "port_id" &&
            e.transportMethod_id === 1 &&
            port === 1 &&
            valor === null;

          if (
            (key === "port_id" &&
              e.transportMethod_id === 1 &&
              currentDataForm?.reentry_operation === 1) ||
            (key === "port_id" && (code < 100 || code >= 200))
          ) {
            return;
          }

          if (
            (((valor === null || valor === undefined || !valor) &&
              key !== "port_id") ||
              (Number(valor) === 0 && // cualquier dato que llegue null y lo pases por funcion number lo combierte a cero
                key !== "us_freight" &&
                key !== "port_id" &&
                !arrayOpeExce.includes(code)) ||
              (key === "us_freight" &&
                Number(code) !== 120 && // pendiente validar flete corregido
                Number(code) >= 101 &&
                Number(code) <= 199 &&
                iteradorStr(valor)) ||
              validatePort) &&
            arrayPrueba.includes(key)
          ) {
            objectField[key] = true;
            if (!arrayIdItem.includes(e.id)) {
              arrayIdItem.push(e.id);
            }
            localStorage.setItem(
              `erroField-${e.id}`,
              JSON.stringify(objectField)
            );
            return localStorage.setItem(
              `erroItem-${idfmmForItems}`,
              JSON.stringify(arrayIdItem)
            );
          }
        });
      });
      return arrayIdItem;
    } catch (error) {
      console.log(error);
    }
  };

  const getItemsPrevOutput = async (endpoint, idfmmForItems) => {
    try {
      const res = await __GetDataId(endpoint, token, idfmmForItems);
      const data = res.data.status.data;
      const arrayIdItem = [];

      data.map((e) => {
        Object.entries(e).map(([key, valor]) => {
          if (
            (valor === null || valor === undefined) &&
            arrayPruebaOutput.includes(key)
          ) {
            if (!arrayIdItem.includes(e.id)) {
              arrayIdItem.push(e.id);
            }

            return localStorage.setItem(
              `erroItem-${idfmmForItems}`,
              JSON.stringify(arrayIdItem)
            );
          }
        });
      });
      return arrayIdItem;
    } catch (error) {
      console.log(error);
    }
  };

  const BulkLoad = async (endpoint, data, onCloseImport) => {
    try {
      const res = await __PostForm(endpoint, token, data);

      const respuestaAttachment = res.data.status;
      if (respuestaAttachment.code === 200) {
        openAlert(`${respuestaAttachment.data}`, "success");
      } else {
        openAlert(`${respuestaAttachment.message}`, "error");
      }
      setUpdate(!update);
      onCloseImport();
    } catch (error) {
      console.log(error);
    } finally {
      onCloseImport();
    }
  };

  const data = {
    CorrectItem,
    typeProduct,
    isOpenComment,
    onOpenComment,
    onCloseComment,
    selectAD,
    setSelectAD,
    dataToDesechar,
    setDataToDesechar,
    isOpenDesechar,
    onOpenDesechar,
    onCloseDesechar,
    dataToCopyItems,
    setDataToCopyItems,
    changeStatus,
    selectNE,
    setSelectNE,
    setRequestChangeStatusForm,
    isOpenCopy,
    onOpenCopy,
    onCloseCopy,
    dataToCopy,
    setDataToCopy,
    copyData,
    editDataForm,
    EditItems,
    isOpenEdit,
    onOpenEdit,
    dataToEdit,
    dataToEditItems,
    show,
    setShow,
    requestCreateItem,
    setRequestCreateItem,
    isOpen,
    onToggle,
    setDataToEditItems,
    setDataToEdit,
    dataToDelete,
    deleteData,
    isOpenDelete,
    onOpenDelete,
    requestCopy,
    setRequestCopy,
    setDataToDelete,
    isOpenShow,
    onOpenShow,
    dataToShow,
    setDataToShow,
    showData,
    setRequestEdit,
    setRequestDelete,
    setFormEdit,
    formEdit,
    setFormShow,
    formShow,
    requestCreateThird,
    setRequestCreateThird,
    formLicensias,
    setFormLicensias,
    formDocument,
    setDocument,
    onCloseModal,
    setRequestCreate,
    createData,
    update,
    setUpdate,
    dataOperation,
    setDataOperation,
    dataDepartament,
    setDataDepartament,
    valueDepartament,
    setValueDepartament,
    departament,
    setDepartament,
    userQualified,
    setUserQuialified,
    valueQualifiedForm,
    setValueQuialifiedForm,
    nameQualifiedForm,
    setNameQualifiedForm,
    dataCountry,
    setDataCountry,
    info,
    setInfo,
    dataCountryOrigin,
    setDataCountryOrigin,
    dataCountryBuy,
    setDataCountryBuy,
    unidad,
    setUnidad,
    placeU,
    setPlaceU,
    dataTransportMethod,
    setDataTransportMethod,
    valueMethod,
    setValueMethod,
    etiqueta,
    setEtiqueta,
    valueQualified,
    setValueQuialified,
    nameQualified,
    setNameQualified,
    selectUF,
    setSelectUF,
    selectItemForm,
    setSelectItemForm,
    selectIdOp,
    setSelectIdOp,
    dataThird,
    setDataThird,
    valueThird,
    setValueThird,
    nameThird,
    setNameThird,
    valueFlag,
    setValueFlag,
    DocumentOperation,
    setDocumentOperation,
    dataAgreements,
    setDataAgreements,
    acuerdos,
    setAcuerdos,
    dataPackagings,
    setDataPackagings,
    packagings,
    setPackagings,
    selectCurrency,
    setSelectCurrency,
    valueSubheading,
    setValueSubheading,
    nameSubheading,
    setNameSubheading,
    dataSubheading,
    setDataSuheading,
    portM,
    setPortM,
    inspeccion,
    setInspeccion,
    addItem,
    CreateItems,
    requestEditItem,
    setRequestEditItem,
    dataProducts,
    setDataProducts,
    nameProducts,
    setNameProducts,
    valueProducts,
    setValueProducts,
    dataTrm,
    setDataTrm,
    nameOperation,
    setNameOperation,
    deleteAttachment,
    createCommentary,
    setNewCommentary,
    newCommentary,
    ImportItems,
    newDispacthwithItem,
    requestCreatePassNew,
    setRequestCreatePassNew,
    dataTypeProduct,
    setDataTypeProduct,
    selectDataTypeProduct,
    setSelectDataTypeProduct,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    ReturnReason,
    isOpenCompany,
    onOpenCompany,
    onCloseCompany,
    setReturnReason,
    isOpenCorrection,
    onOpenCorrection,
    onCloseCorrection,
    dataCorrection,
    dataPackagingsNew,
    setDataPackagingsNew,
    dataTransportMethodNew,
    setDataTransportMethodNew,
    setDataCorrection,
    dataAgreementsNew,
    setDataAgreementsNew,
    ObtenerCosto,
    dataCostos,
    allProducts,
    setDataCostos,
    getThirdParties,
    getOperationNotPaginate,
    getMethodTransport,
    getCountry,
    getPackaging,
    getAcuerdosC,
    getCodeProduct,
    CorrectDocument,
    requestCorrectDocument,
    setRequestCorrectDocument,
    getDepartament,
    getAdminCustom,
    adminCustom,
    dataDepartamentNew,
    setDataDepartamentNew,
    StateCountry,
    setStateCountry,
    getUserQualified,
    userQualifiedNew,
    setUserQuialifiedNew,
    StateNit,
    setStateNit,
    selectExport,
    setselectExport,
    StateCurrentExport,
    setStateCurrentExport,
    selectOperation,
    setselectOperation,
    selectEmbarcacion,
    setselectEmbarcacion,
    dataSelectOperation,
    setDataSelectOperation,
    dataSelectExport,
    setDataSelectExport,
    ShowPayment,
    updateCorrectDocument,
    isOpenCaducar,
    onOpenCaducar,
    onCloseCaducar,
    dataToCaducar,
    setDataToCaducar,
    isOpenAnular,
    dataAsocciet,
    setdataAsocciet,
    onOpeAnular,
    onCloseAnular,
    dataToAnular,
    setDataToAnular,
    deleteItemsCorrect,
    inspeccionCorrect,
    setInspeccionCorrect,
    portMCorrect,
    setPortMCorrect,
    typeEntry,
    setTypeEntry,
    dataType,
    typeEntryCurrent,
    setTypeEntryCurrent,
    addresDestine,
    dataThirdDefault,
    setDataThirdDefault,
    getOperationOutput,
    dataDUTA,
    opcional,
    setOpcional,
    opcionalCurrent,
    setOpcionalCurrent,
    refunds,
    setRefunds,
    loadingItems,
    setLoadingItems,
    handleCorrection,
    dataNational,
    getItemsPrev,
    stateCollapse,
    setstateCollapse,
    showItemsView,
    setshowItemsView,
    changeStatusCorregido,
    isOpenRadicar,
    onOpenRadicar,
    onCloseRadicar,
    isOpenAsoccietUnit,
    onOpenAsoccietUnit,
    onCloseAsoccietUnit,
    dataRadicar,
    setDataRadicar,
    getItemsPrevOutput,
    dataRecuperar,
    setDataRecuperar,
    isOpenRecuperar,
    onOpenRecuperar,
    onCloseRecuperar,
    isOpenSearchItem,
    onOpenSearchItem,
    onCloseSearchItem,
    requestObtenerItem,
    setRequestObtenerItem,
    addItemPass,
    editItemDispatch,
    seteditItemDispatch,
    dataItemDispachSelect,
    setDataItemDispachSelect,
    messageModalAsocciet,
    setmessageModalAsocciet,
    decimalUcContext,
    setDecimalUcContext,
    dataProcedencia,
    dataCountry120,
    setDataCountry120,
    setAllProducts,
    setDataProcedencia,
    showCorrectBalance,
    setshowCorrectBalance,
    balanceCorrecedState,
    setbalanceCorrecedState,
    BulkLoad,
    changeQualified,
    setChangeQualified,
    onFmm,
    setOnFmm,
    customsadministration,
    setCustomsadministration,
    ReviewsData,
    setReviewsData,
    isOpenDesCaducar,
    onOpenDesCaducar,
    onCloseDesCaducar,
    dataDescaducar,
    setDataDescaducar,
    flagUpdateReviews,
    setflagUpdateReviews,
    changeExportExcel,
    setChangeExportExcel,
    searchAdvancedPosition,
    setSearchAdvancedPosition,
    verifiedObservations,
    setVerifiedObservations,
    userImage,
    setUserImage,
    updateThirdParties,
    setupdateThirdParties,
    exportColumnBy,
    exportPDFComponent,
    setExportPDFComponent,
    currentItemLength,
    setCurrentItemLength,
    valorDolarActual,
    setValorDolarActual,
    dataFormCurrent,
    setDataFormCurrent,
    stateCurrent,
    setStateCurrent,
    letNumDepartureAuthorization,
    setLetNumDepartureAuthorization,
    dataForm,
    setDataForm,
    isSelectedAllItems,
    setIsSelectedAllItems,
    itemsSelected,
    setItemsSelected,
    isOpenSelectAuthorizationItems,
    onOpenSelectAuthorizationItems,
    onCloseSelectAuthorizationItems,
    isLoadingChangeStatus,
    setIsLoadingChangeStatus
  };
  return (
    <FormCrudCRUDContext.Provider value={data}>
      {children}
    </FormCrudCRUDContext.Provider>
  );
};

export default FormCrudCRUDContext;
