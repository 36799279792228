import { Box, SimpleGrid } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert';
import environment from 'constants/apiConst';
import FormCrudCRUDContext from 'context/FormCrudContex';
import IntegracionBoxCrudContex from 'context/IntegracionBoxCrudContex';
import ProductSearchBar from 'pages/IntegrationBox/Form/ProductSearchBar';
import { LabelGroup } from 'pages/RefProductInter/LabelGroup';
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { __Post } from 'request/Petitions/__Post';
import { __SelectPaises } from 'request/configuration/_Country';
import { __Packaging_All } from 'request/configuration/__Packaging';
import { __TransportMethodNotPaginate } from 'request/configuration/__TransportMethod';
import styled from 'styled-components';
import { ButtonStyled } from 'styled/Buttons.styled';
import { Title } from 'styled/Title';
import queryString from "query-string";
import { useLocation } from 'react-router-dom';
import { __Trm } from 'request/configuration/__Trm';
import { getFormData } from 'redux/actions/dataFormActions';
import InputSelect2 from './InputSelect2';

export const ErrorSpan = styled.span`
  color: red;
  font-size: 12px;
  display: flex;
  margin-top: 2px;
`;

const AddProductsForm = ({ setIsOpenAddProductForm, isOpenAddProductForm }) => {
  const [valueProduct, setValueProduct] = useState({});
  const [products, setProducts] = useState([]);
  const { dataToEditSingleProduct } = useContext(IntegracionBoxCrudContex)
  const { decimal_values_precision, token } = useSelector((state) => state.auth.dataUser);
  const dataForm = useSelector(getFormData)
  const [dataCountry, setDataCountry] = useState([]);
  const [countryDestiny_id, setCountryDestination] = useState(null);
  const [flag, setflag] = useState(null);
  const [transportMethod_id, setMethodTransport] = useState(null);
  const [dataTransportMethod, setDataTransportMethod] = useState([]);
  const [packagings, setPackagings] = useState(null);
  const [dataPackagings, setDataPackagings] = useState([]);
  const [cif_us_value, setCifUsValue] = useState(null);
  const [cif_cop_value, setCifCopValue] = useState(null);
  const { dataCountry120, setDataCountry120, setDataProcedencia, setUpdate, update } = useContext(FormCrudCRUDContext);
  const [trm, setTrm] = useState(null);
  const location = useLocation(); // navigate
  const { FormExitNumber = "" } = queryString.parse(
    location.search
  );
  const [openAlert] = AlertErrorAlert();
  const decimalScale = dataForm?.precision || decimal_values_precision || 4;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch
  } = useForm({
    defaultValues: {
      outputForm_id: FormExitNumber,
      product_id: "",
      product_quantity: "",
      countryDestiny_id: "",
      flag_id: "",
      transportMethod_id: "",
      gross_weight: "",
      net_weight: "",
      number_packages: "",
      us_freight: "",
      us_insurance: "",
      other_us_expenses: "",
      us_fob_value: "",
      cif_us_value: "",
      cif_cop_value: "",
      packaging_id: "",
      exchange_rate: ""
    }
  });

  const watchedFields = watch(['us_fob_value', 'us_freight', 'us_insurance', 'other_us_expenses']);
  const watchedTrm = watch(['exchange_rate']);

  useEffect(() => {
    const currentValues = getValues();
    const sum = +currentValues.us_fob_value + +currentValues.us_freight + +currentValues.us_insurance + +currentValues.other_us_expenses
    setCifUsValue(sum)
    setValue("cif_us_value", sum)
  }, [watchedFields])

  useEffect(() => {
    const exchangeRate = getValues("exchange_rate")
    setCifCopValue(exchangeRate * cif_us_value)
    setValue("cif_cop_value", exchangeRate * cif_us_value)
  }, [cif_us_value, watchedTrm])

  const getCountry = async (codeOperations) => {
    try {
      const res = await __SelectPaises(token);
      const infoCountry = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_country + " " + item?.name_country,
        origin_country: item?.origin_country,
        code: item?.code_country
      }));

      setDataCountry(infoCountry);
      if (
        codeOperations &&
        ((codeOperations >= 401 && codeOperations <= 499) ||
          (codeOperations >= 601 && codeOperations <= 699))
      ) {
        const serachIZF = infoCountry.filter(({ code }) => code === "169");
        return setDataCountry120(serachIZF);
      } else if (
        codeOperations &&
        codeOperations >= 801 &&
        codeOperations <= 899
      ) {
        const serachIZF = infoCountry.filter(({ code }) => code === "911");
        return setDataCountry120(serachIZF);
      } else {
        setDataProcedencia(null);
        setDataCountry120(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getMethodTransport = async () => {
    try {
      const res = await __TransportMethodNotPaginate(token);
      const infoMethod = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_transportMethod + " " + item?.name_transportMethod
      }));
      setDataTransportMethod(infoMethod);
    } catch (error) {
      console.log(error);
    } finally {

    }
  };

  const getPackaging = async () => {
    try {
      const res = await __Packaging_All(token);
      const infoPackagin = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_packaging + " " + item?.name_packaging
      }));
      setDataPackagings(infoPackagin);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getTrm = async () => {
    try {
      const res = await __Trm(token);
      const trmFormat = parseFloat(res?.data?.status?.data.value_trm);
      setTrm(trmFormat);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: ([url, authToken, data]) => {
      return __Post(url, authToken, data);
    },
    onSuccess: (res) => {
      if (res?.data?.status?.code === 200) {
        openAlert(res?.data?.status?.message, 'success')
        setUpdate(!update)
        onClose()
      }

      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, 'error')
      }

      if (res?.data?.status?.code === 401) {
        openAlert(res?.data?.status?.message, 'error')
      }
    },
    onError: (err) => {
      console.log(err, "error");
    }
  });

  const onSubmit = (data) => {
    if (!isLoading) {
      mutate([
        environment.SAVE_PRODUCTS_TO_OUTPUT_FORM,
        token,
        data
      ])
    }
  }

  const onClose = (e) => {
    e.preventDefault();
    setIsOpenAddProductForm(!isOpenAddProductForm);
    reset();
  }

  const getProductsOnSearch = (hasProductData) => {
    setProducts(hasProductData);
  };

  useEffect(() => {
    getCountry();
    getMethodTransport();
    getPackaging();
    getTrm();
  }, []);

  useEffect(() => {
    setValue('product_id', valueProduct?.value || '')
    setValue('countryDestiny_id', countryDestiny_id?.value || '')
    setValue('flag_id', flag?.value || '')
    setValue("transportMethod_id", transportMethod_id?.value || '')
    setValue('packaging_id', packagings?.value || '')
    setValue("exchange_rate", trm || '')
  }, [valueProduct, countryDestiny_id, flag, transportMethod_id, packagings, trm])

  useEffect(() => {
    setValueProduct({
      value: dataToEditSingleProduct?.product_id,
      label: dataToEditSingleProduct?.description_product
    });
  }, [dataToEditSingleProduct])

  return (
    <div style={{ backgroundColor: "#cfcfcf5d", padding: "10px", marginTop: "10px" }}>
      <Title>Agregar producto</Title>
      <form>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <ProductSearchBar
            label="Código de producto"
            required={true}
            set={setValueProduct}
            size={100}
            value={valueProduct}
            callBack={getProductsOnSearch}
          />

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('product_quantity', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              tag={"Cantidad de producto"}
              name={"product_quantity"}
              size={100}
              required={true}
              error={errors.product_quantity}
              maxLength={34}
              {...register('product_quantity', {
                required: {
                  value: true,
                  message: 'Cantidad de producto es requerido'
                }
              })}
            />
            {errors.product_quantity && (<ErrorSpan>{errors.product_quantity.message}</ErrorSpan>)}
          </div>

          <div>
            <InputSelect2
              tag={"País de destino"}
              size={100}
              name={"countryDestiny_id"}
              data={dataCountry120 || dataCountry}
              error={errors.countryDestiny_id}
              set={setCountryDestination}
              value={countryDestiny_id}
              required={true}
              {...register('countryDestiny_id', {
                required: {
                  value: true,
                  message: 'País de destino es requerido'
                }
              })}
            />
            {errors.countryDestiny_id && (<ErrorSpan>{errors.countryDestiny_id.message}</ErrorSpan>)}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <div>
            <InputSelect2
              tag={"Bandera"}
              size={100}
              name={"flag_id"}
              error={errors.flag_id}
              data={dataCountry}
              set={setflag}
              value={flag}
              required={true}
              {...register('flag_id', {
                required: {
                  value: true,
                  message: 'Bandera es requerido'
                }
              })}
            />
            {errors.flag_id && (<ErrorSpan>{errors.flag_id.message}</ErrorSpan>)}
          </div>

          <div>
            <InputSelect2
              tag={"Modo de transporte"}
              size={100}
              name={"transportMethod_id"}
              error={errors.transportMethod_id}
              data={dataTransportMethod}
              set={setMethodTransport}
              value={transportMethod_id}
              required={true}
              {...register('transportMethod_id', {
                required: {
                  value: true,
                  message: 'Modo de transporte es requerido'
                }
              })}
            />
            {errors.transportMethod_id && (<ErrorSpan>{errors.transportMethod_id.message}</ErrorSpan>)}
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('gross_weight', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              tag={"Peso Bruto"}
              size={100}
              name={"gross_weight"}
              error={errors.gross_weight}
              maxLength={34}
              {...register('gross_weight', {
              })}
            />
            {errors.gross_weight && (<ErrorSpan>{errors.gross_weight.message}</ErrorSpan>)}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('net_weight', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              tag={"Peso neto (Calc: 0)"}
              size={100}
              name={"net_weight"}
              error={errors.net_weight}
              maxLength={34}
              {...register('net_weight', {
              })}
            />
            {errors.net_weight && (<ErrorSpan>{errors.net_weight.message}</ErrorSpan>)}
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('number_packages', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              tag={"Numero de bultos"}
              size={100}
              name={"number_packages"}
              error={errors.number_packages}
              maxLength={34}
              {...register('number_packages', {
              })}
            />
            {errors.number_packages && (<ErrorSpan>{errors.number_packages.message}</ErrorSpan>)}
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('us_freight', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              tag={"Flete"}
              size={100}
              name={"us_freight"}
              error={errors.us_freight}
              maxLength={34}
              {...register('us_freight', {
              })}
            />
            {errors.us_freight && (<ErrorSpan>{errors.us_freight.message}</ErrorSpan>)}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('us_insurance', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              tag={"Seguros"}
              size={100}
              name={"us_insurance"}
              error={errors.us_insurance}
              maxLength={34}
              {...register('us_insurance', {
              })}
            />
            {errors.us_insurance && (<ErrorSpan>{errors.us_insurance.message}</ErrorSpan>)}
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('other_us_expenses', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              tag={"Otros gastos"}
              size={100}
              name={"other_us_expenses"}
              error={errors.other_us_expenses}
              maxLength={34}
              {...register('other_us_expenses', {
              })}
            />
            {errors.other_us_expenses && (<ErrorSpan>{errors.other_us_expenses.message}</ErrorSpan>)}
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('us_fob_value', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              tag={"Valor FOB US$"}
              size={100}
              name={'us_fob_value'}
              error={errors.us_fob_value}
              maxLength={34}
              {...register('us_fob_value', {
              })}
            />
            {errors.us_fob_value && (<ErrorSpan>{errors.us_fob_value.message}</ErrorSpan>)}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('cif_us_value', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              tag={"Valor CIF US$"}
              value={cif_us_value}
              desicion={true}
              size={100}
              name={'cif_us_value'}
              error={errors.cif_us_value}
              maxLength={34}
              {...register('cif_us_value', {
                validate: (value) => {
                  if (value === 0) {
                    return 'Valor CIF US$ debe ser diferente de 0'
                  }
                }
              })}
            />
            {errors.cif_us_value && (<ErrorSpan>{errors.cif_us_value.message}</ErrorSpan>)}
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('cif_cop_value', values.value);
              }}
              customInput={LabelGroup}
              decimalSeparator="."
              decimalScale={decimalScale}
              tag={"Valor CIF COL$"}
              value={cif_cop_value}
              size={100}
              name={'cif_cop_value'}
              desicion={true}
              error={errors.cif_cop_value}
              maxLength={34}
              {...register('cif_cop_value', {
                validate: (value) => {
                  if (value === 0) {
                    return 'Valor CIF COL$ debe ser diferente de 0'
                  }
                }
              })}
            />
            {errors.cif_cop_value && (<ErrorSpan>{errors.cif_cop_value.message}</ErrorSpan>)}
          </div>

          <div>
            <InputSelect2
              tag={"Embalaje"}
              size={100}
              name={"packaging_id"}
              error={errors.packaging_id}
              data={dataPackagings}
              set={setPackagings}
              value={packagings}
              {...register('packaging_id')}
            />
            {errors.packaging_id && (<ErrorSpan>{errors.packaging_id.message}</ErrorSpan>)}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('exchange_rate', values.value);
              }}
              decimalSeparator="."
              decimalScale={decimalScale}
              customInput={LabelGroup}
              placeholder={trm}
              tag={"Tasa de cambio"}
              size={100}
              name={"exchange_rate"}
              error={errors.exchange_rate}
              maxLength={34}
              {...register('exchange_rate', {
              })}
            />
            {errors.exchange_rate && (<ErrorSpan>{errors.exchange_rate.message}</ErrorSpan>)}
          </div>
        </SimpleGrid>

        <Box
          display="flex"
          alignItems="center"
          marginTop={"20px"}
          marginBottom={"20px"}
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
            Cancelar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit(onSubmit)} disabled={isLoading}>
            Añadir
          </ButtonStyled>
        </Box>
      </form>
    </div >
  )
}

export default AddProductsForm
